export const IMAGE_FILE_SIZE_VIOLATION = 'File size must be less than 5 mb.'
export const ROLE_SUCCESSFULLY_CHANGED = 'Role successfully changed.'
export const EMAIL_REQUIRED = 'Email should not be blank.'
export const INVITE_EMAIL_REQUIRED = 'Invite email should not be blank.'
export const ONE_INVITEE_EMAIL_REQUIRED =
  'At least one invitee email is required.'
export const EMAIL_SIZE_VALIDATION =
  'Email should not be bigger than 45 character.'
export const EMAIL_REG_EXP_VALIDATION = 'Please enter a valid email address.'
export const PHONE_REG_EXP_VALIDATION =
  'Please enter a valid phone number (starting with + and the country code).'
export const EMAIL_OR_PHONE_REG_EXP_VALIDATION =
  'Please enter a valid email address or phone number (starting with + and the country code).'
export const NAME_REG_EXP_VALIDATION =
  'Display Name can only contain a-z, A-Z, 0-9 space and accent characters.'
export const TEAM_REG_EXP_VALIDATION = `Team Name can only contain a-z, A-Z, 0-9, _ , + , # , - , ' , period(.) and space.`
export const USERNAME_REQUIRED = 'Username should not be blank.'
export const USERNAME_MIN_LENGTH_REQUIRED =
  'Username must be at least 2 characters.'
export const USERNAME_MAX_LENGTH__REQUIRED =
  'Username should not be bigger than 30 character.'
export const USERNAME_REG_EXP_VALIDATION =
  'Username can only contain a-z, A_Z, 0-9, _ , # , - and period(.).'
export const FULL_NAME_REQUIRED = 'Full Name should not be blank.'
export const FULL_NAME_MIN_LENGTH_REQUIRED =
  'Full Name must be at least 3 characters'
export const FULL_NAME_MAX_LENGTH__REQUIRED =
  'Full Name should not be bigger than 30 character.'
export const FULL_NAME_REG_EXP_VALIDATION =
  'Full Name can only contain a-z, A-Z, 0-9 space and accent characters.'
export const ONLY_NUMBERS_ALLOWED = 'Only numbers are allowed.'
export const VERIFICATION_CODE_REQUIRED = 'Verification code can not be blank.'
export const VALID_CODE_REQUIRED = 'Provide valid code of 6 digits.'
export const DUPLICATE_EMAIL = 'Duplicate email address.'
export const TEAM_SEARCH_STRING_VALIDATION = `Search can only contain a-z, A-Z, 0-9, @ , # space and accent characters.`
export const CARD_NUMBER_REQUIRED_MESSAGE = 'Card Number is required.'
export const CARD_EXPIRY_REQUIRED_MESSAGE =
  'Expiration Month and Year is required.'
export const CARD_CVV_REQUIRED_MESSAGE = 'Card CVV is required.'
export const DISPLAY_NAME_REQUIRED = 'Display Name should not be blank.'
export const DISPLAY_NAME_MIN_LENGTH_REQUIRED =
  'Display Name must be at least 3 characters long.'
export const DISPLAY_NAME_MAX_LENGTH__REQUIRED =
  'Display Name should not be bigger than 30 character.'
export const TEAM_NAME_REQUIRED = 'Team Name should not be blank.'
export const TEAM_NAME_MIN_LENGTH_REQUIRED =
  'Team Name must be at least 3 characters long.'
export const TEAM_NAME_MAX_LENGTH__REQUIRED =
  'Team Name should not be bigger than 30 character.'
export const STREET_ADDRESS_REQUIRED = 'Street Address should not be blank.'
export const COUNTRY_REQUIRED = 'Country should not be blank.'
export const CITY_REQUIRED = 'City should not be blank.'
export const STATE_REQUIRED = 'State should not be blank.'
export const ZIP_CODE_REQUIRED = 'Zip Code should not be blank.'
export const FULL_NAME_CARD_REQUIRED = 'Full Name on Card should not be blank.'
export const TERMS_CHECKED_REQUIRED =
  'Please check the box accepting the terms of New Teams.'
export const ADMIN_ROLE_REQUIRED = 'Admin role require to change.'
export const CONFIRMATION_TEXT_REQUIRED =
  'Confirmation text should not be blank.'
export const VALID_CONFIRMATION_TEXT_REQUIRED = 'Please enter valid keyword.'
export const INVALID_INVITATION_LINK = 'Invalid invitation link.'
export const YAC_CODE_SENT_SUCCESS = 'Yac code successfully sent again.'
export const ERROR_IN_SENT_YAC_CODE = 'Unable to send Yac code.'
export const INVITATIONS_SENT_SUCCESS =
  'Invitations sent successfully to all the users!'
export const PROMO_CODE_REQUIRED = 'Please enter PROMO CODE to apply!'
export const PROMO_CODE_APPLIED = 'Promo code applied!'
export const INVALID_PROMO_CODE = 'Invalid code!'
export const PROMO_CODE_REMOVED = 'Promo code removed!'
export const PAYMENT_METHOD_UPDATE_SUCCESS = 'Information updated successfully!'
export const UPGRADE_PLAN_ERROR = 'Error in upgrading plan'
export const USER_UNSUBSCRIBED_SUCCESS = 'User sucessfully unsubscribed!'
export const USER_UNSUBSCRIBED_ERROR =
  'Error in unsubscribing user. Try another time'
export const URL_COPY_TO_CLIPBOARD = 'URL copied to clipboard.'
export const PAYMENT_FORM_USER_NAME_REQUIRED = 'Please input valid name.'
export const VALID_TEAM_IMAGE_REQUIRED =
  'Please select a valid image for team photo'
export const VALID_PROFILE_IMAGE_REQUIRED =
  'Please select a valid image for profile photo'
export const ACCOUNT_DOWNGRADE_SUCCESS = 'Account downgrade successfully.'
export const ACCOUNT_DOWNGRADE_ERROR = 'Error in downgrade account, try again.'
