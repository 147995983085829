import { toast } from 'react-toastify'
import moment from 'moment'

export const successToast = (message, onClose = () => {}) => {
  if (!message) return
  toast.success(message, {
    onClose,
  })
}

export const errorToast = (message, onClose = () => {}) => {
  if (!message) return
  toast.error(message, {
    onClose,
  })
}

export const infoToast = (message, onClose = () => {}) => {
  if (!message) return
  toast.info(message, {
    onClose,
  })
}

export const convertImageToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      try {
        resolve(e.target.result)
      } catch (e) {
        reject(e)
      }
    }
    reader.readAsDataURL(file)
  })
}

export const updateMetadata = pageName => {
  document.title = `Yac | Admin Dashboard - ${pageName}`
}

export const timeStampToDateFormat = timeStamp => {
  return moment(timeStamp).format('MMMM Do, YYYY')
}

export const getSubstring = (string, to) => {
  return string.toString().substr(string.toString().length - to)
}

export const minTwoDigits = n => {
  return (n < 10 ? '0' : '') + n
}

const files = {}
export const getObjectURL = ({ id, file }) => {
  if (files?.[id]) {
    window.URL.revokeObjectURL(files[id])
  }
  if (file) {
    files[id] = window.URL.createObjectURL(file)
  }
  return files?.[id]
}

export const filterArrayWithKey = (array, index) => {
  return array.filter((item, key) => key !== index).filter(Boolean)
}

export const fixedAmountDigit = (amount, toFixed = 2) => {
  return amount.toFixed(toFixed)
}
