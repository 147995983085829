import ApiRequestService from 'services/api.service'
import objectToFormData from 'object-to-formdata'
import { content_types, auth_header } from 'services/utils'

const api = new ApiRequestService()

export type ITeam = {
  role: string,
  teamId: number,
}

export type IPlan = {
  id: string,
  object: string,
  active: boolean,
  cardAvailable: boolean,
  inTrialDays: boolean,
  aggregate_usage: string | null | any,
  amount: number,
  amount_decimal: string,
  billing_scheme: string,
  created: number,
  currency: string,
  interval: string,
  interval_count: number,
  livemode: boolean,
  metadata: object,
  nickname: string,
  product: string,
  tiers: string | null | any,
  tiers_mode: string | null | any,
  transform_usage: string | null | any,
  usage_type: string,
  remaining_days: number,
  trial_period_days: number,
  start_date: number,
  end_date: number,
}

export type IUserData = {
  id: number,
  email: string,
  image: string,
  mobileImage: string,
  subscribeUnreadNotification: number,
  onBoardingDesktop: number,
  onBoardingMobile: number,
  imageUploaded: number,
  realName: string,
  role: string,
  languageCode: string,
  secret: string,
  salt: string,
  deviceToken: string,
  deviceOs: string,
  stripeCustomerId: string,
  stripeSubscriptionId: string,
  skipUsername: boolean,
  teams: ITeam[],
  teamId: number,
  teamSize: number,
  privateTeamSize: number,
  teamImageUrl: string,
  teamName: string,
  token: string,
  username: string,
  isMainAdmin: boolean,
  plan?: IPlan,
}

/* sing-up | start */
export type ISingUpApiParams = {
  name: string,
  email: string,
  username: string,
}

export type ISingUpApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
}

export const singUpApi = (params: ISingUpApiParams): ISingUpApiResponse => {
  return api.postApi('v1/users/signup', params, {
    ...content_types.json,
  })
}

export const signUpApiV2 = async (
  params: ISingUpApiParams
): ISingUpApiResponse => {
  const response = await api.postApi('v1/users/signup', params, {
    ...content_types.json,
  })

  if (response.status) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
/* sing-up | end */

/* sing-in | start */
export type ISingInApiParams = {
  email?: string,
  phone?: String,
  isDashboard?: number,
}

export type ISingInApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
}

export const singInApi = (params: ISingInApiParams): ISingInApiResponse => {
  return api.postApi('v1/users/signin', params, {
    ...content_types.json,
  })
}

export const signInApiV2 = async (
  params: ISingInApiParams
): ISingInApiResponse => {
  const response = await api.postApi(
    'v1/users/signin',
    { ...params, isDashboard: 1 },
    {
      ...content_types.json,
    }
  )

  if (response.status) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
/* sing-in | end */

/* email-confirmation | start */
export type IEmailConfirmationApiParams = {
  email: string,
  loginCode: number,
}

export type IEmailConfirmationApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IUserData,
}

export const emailConfirmationApi = (
  params: IEmailConfirmationApiParams
): IEmailConfirmationApiResponse => {
  return api.postApi('v1/users/login', params, {
    ...content_types.json,
  })
}

export const emailConfirmationApiV2 = async (
  params: IEmailConfirmationApiParams
): IEmailConfirmationApiResponse => {
  const response = await api.postApi('v1/users/login', params, {
    ...content_types.json,
  })

  if (response.status) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
/* email-confirmation | end */

/* resend-confirmation-code | start */
export type IResendConfirmationCodeApiParams = {
  email: string,
  isDashboard?: number,
}

export type IResendConfirmationCodeApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IUserData,
}

export const resendConfirmationCodeApi = (
  params: IResendConfirmationCodeApiParams
): IResendConfirmationCodeApiResponse => {
  return api.postApi('v1/users/signin', params, {
    ...content_types.json,
  })
}
/* resend-confirmation-code | end */

/* team-creation | start */
export type ITeamCreationApiParams = {
  name: string,
  invities: string,
  key?: string,
  file?: string,
}

export type ITeamCreation = {
  teamId: number,
  teamName: string,
  teamImageUrl: string,
  plan?: IPlan,
}

export type ITeamCreationApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamCreation,
}

export const teamCreationApi = (
  params: ITeamCreationApiParams
): ITeamCreationApiResponse => {
  const data = objectToFormData(params)
  return api.postApi('v1/team/create', data, {
    ...content_types.multipart,
    ...auth_header(),
  })
}
/* team-creation | end */

export type ITeamCreationUpdateParams = {
  teamId: number,
  name: string,
  invities: string,
  key?: string,
  file?: string,
}

/* check-username | start */
export type IUsernameCheckApiParams = {
  name?: string,
  email?: string,
  username: string,
}

export type IUsernameCheckApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: string,
}

export const checkUsernameApi = (
  params: IUsernameCheckApiParams
): IUsernameCheckApiResponse => {
  const { username } = params
  return api.getApi(`v1/users/check-username/${username}`)
}
/* check-username | end */
