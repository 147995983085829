import React from 'react'
import { toJS } from 'mobx'
import { Route, Redirect } from 'react-router-dom'
import { routes as routePaths, routes } from 'config/routes'
import { updateMetadata } from 'utils'

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const {
        pageName,
        AuthStore: { state },
      } = rest

      const userData = toJS(state.userData)

      updateMetadata(pageName)

      if (
        !userData ||
        pageName === routes.playMessage.name ||
        pageName === routes.emailconfirmation.name ||
        pageName === routes.createMeeting.name
      ) {
        return <Component {...props} />
      } else {
        return (
          <Redirect
            to={{
              pathname: routePaths.teamsetup.path,
              state: { from: props.location },
            }}
          />
        )
      }
    }}
  />
)
