import { observable, action } from 'mobx'
import jwtDecode from 'jwt-decode'
import { toJS } from 'mobx'
import { routes, history } from 'config/routes'
import {
  getState,
  setState,
  clearState,
  getCookie,
} from 'services/localStorage'
import {
  confirmUpdateUserEmailApi,
  getSessionApi,
  updateUserEmailApi,
} from 'services/user'
import {
  IUserData,
  singUpApi,
  ISingUpApiParams,
  singInApi,
  ISingInApiParams,
  emailConfirmationApi,
  checkUsernameApi,
  IEmailConfirmationApiParams,
  resendConfirmationCodeApi,
  IResendConfirmationCodeApiParams,
  teamCreationApi,
  ITeamCreationApiParams,
  IUsernameCheckApiParams,
} from 'services/auth'
import { errorToast, successToast } from 'utils'
import { ERROR_IN_SENT_YAC_CODE } from 'common/messages'
import { parse as parseQueryString } from 'query-string'

export type IInitialState = {
  userData: IUserData,
  token: string,
  ui: {
    isSignUpLoading: boolean,
    isSignInLoading: boolean,
    isEmailConfirmationLoading: boolean,
    isResendConfirmationCodeLoading: boolean,
    isTeamCreationLoading: boolean,
  },
}

export const initialState: IInitialState = {
  /* data key stays in sync with localStorage so store the only values which you want to persist */
  userData: null,
  token: null,
  ui: {
    isSignUpLoading: false,
    isSignInLoading: false,
    isEmailConfirmationLoading: false,
    isResendConfirmationCodeLoading: false,
    isTeamCreationLoading: false,
  },
}

class AuthStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    /* state hydration from localStorage */
    const userData = getState('userData')
    const token = getCookie('token')

    if (token) {
      this.setState({ ...initialState, userData, token })
    } else {
      clearState()
      this.setState(initialState)
    }
  }

  onSingUp = async (params: ISingUpApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isSignUpLoading: true } })
    try {
      const response = await singUpApi(params)
      const { message, status } = response
      if (status) {
        history.push({
          pathname: routes.emailconfirmation.path,
          state: {
            email: params.email,
            type: 'signup',
          },
        })
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isSignUpLoading: false } })
  }

  onSingIn = async (params: ISingInApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isSignInLoading: true } })
    try {
      const response = await singInApi({ ...params, isDashboard: 1 })
      const { message, status } = response
      if (status) {
        history.push({
          pathname: routes.emailconfirmation.path,
          state: {
            email: params.email,
            phone: params.phone,
            type: 'signin',
          },
        })
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isSignInLoading: false } })
  }

  onEmailConfirmation = async (
    params: IEmailConfirmationApiParams,
    isEmailUpdate?: boolean
  ) => {
    const { ui, userData } = this.state
    const { type, ...rest } = params
    this.setState({ ui: { ...ui, isEmailConfirmationLoading: true } })
    try {
      if (isEmailUpdate) {
        await confirmUpdateUserEmailApi(userData.id, params.loginCode)

        const { unconfirmedEmail, ...restOfUserData } = userData

        this.setState({
          userData: {
            ...restOfUserData,
            email: unconfirmedEmail,
          },
          ui: { ...ui, isEmailConfirmationLoading: false },
        })

        history.replace({
          pathname: routes.userProfile.path,
        })
      } else {
        const response = await emailConfirmationApi(rest)
        const { message, status, data } = response

        if (status && data) {
          const { token } = data

          if (data.isFirstLogin) {
            try {
              // eslint-disable-next-line no-undef
              $FPROM.trackSignup({
                email: data.teamEmail,
                function() {
                  console.log('First Promoter callback received!')
                },
              })
            } catch (error) {
              console.log('Error calling First Promoter:\n', error)
            }
          }

          const planName = data.plan && data.plan.metadata && data.plan.metadata.NAME
          const isPaidPlan = planName === "FREELANCER" || planName === "STARTUP";

          if (data.plan) {
            data.plan.isPaid = isPaidPlan
          }
          
          this.setState({
            userData: data,
            token,
            ui: { ...ui, isEmailConfirmationLoading: false },
          })

          if (type==="signin"){
            const { id, username, realName, email, phone } = data
            window.analytics.track("Signed In", { id, user_id: id, username, realName, email, phone });
            window.analytics.identify(id)
          }

          if (type === 'signup') {
            const { id, username, realName, email, phone } = data
            window.analytics.track("Signed Up", { id, user_id: id, username, realName, email, phone, invited: false });
            window.analytics.identify(id)
            history.replace({
              pathname: routes.teamsetup.path,
              state: {
                type: 'signup',
              },
            })
          } else {
            history.replace({
              pathname: routes.myteamsearchadd.path,
            })
          }
        } else {
          errorToast(message)
        }
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isEmailConfirmationLoading: false } })
  }

  onResendConfirmationCode = async (
    params: IResendConfirmationCodeApiParams,
    isEmailUpdate?: boolean
  ) => {
    const { ui, userData } = this.state
    this.setState({ ui: { ...ui, isResendConfirmationCodeLoading: true } })
    try {
      if (isEmailUpdate) {
        await updateUserEmailApi(userData.id, userData.unconfirmedEmail)

        successToast('New code sent.')
      } else {
        const response = await resendConfirmationCodeApi({
          ...params,
          isDashboard: 1,
        })
        const { status } = response
        if (!status) {
          errorToast(ERROR_IN_SENT_YAC_CODE)
        } else {
          successToast('New code sent.')
        }
      }
    } catch (error) {
      errorToast(ERROR_IN_SENT_YAC_CODE)
    }
    this.setState({ ui: { ...ui, isResendConfirmationCodeLoading: false } })
  }

  onTeamCreation = async (params: ITeamCreationApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isTeamCreationLoading: true } })
    try {
      const response = await teamCreationApi(params)
      const { message, status, data } = response
      if (status) {
        const {
          teamId,
          teamImageUrl,
          teamName,
          plan,
          teamSize,
          isMainAdmin,
        } = data
        const user = {
          ...this.state.userData,
          teamId,
          teamImageUrl,
          teamName,
          plan,
          teamSize,
          isMainAdmin,
        }
        this.setState({
          ...this.state,
          userData: user,
          ui: { ...ui, isTeamCreationLoading: false },
        })
        history.push({
          pathname: routes.myteamsearchadd.path,
        })
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isTeamCreationLoading: false } })
  }

  onUpdateTeamCreation = async (params: ITeamCreationUpdateParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isTeamCreationUpdateLoading: true } })
    const {
      SettingsStore: { onUpdateTeamDetails },
      BaseTeamStore: { onInviteTeammates },
      BillingStore
    } = this.rootStore
    const { teamId, invities, ...rest } = params
    const promoCode = BillingStore.state.promoCode && toJS(BillingStore.state.promoCode)

    try {
      const response = await onUpdateTeamDetails({
        teamId,
        ...rest,
      })

      if (response) {
        if (invities && invities.length > 0) {
          const response = await onInviteTeammates({
            invities,
            teamId,
            pageNumber: 0,
          })
          if (response) {
            this.setState({
              ...this.state,
              ui: { ...ui, isTeamCreationUpdateLoading: false },
            })
            history.replace({
              pathname: promoCode ? routes.billing.path : routes.myteamsearchadd.path,
            })
          }
        } else {
          this.setState({
            ...this.state,
            ui: { ...ui, isTeamCreationUpdateLoading: false },
          })
          history.replace({
            pathname: promoCode ? routes.billing.path : routes.myteamsearchadd.path,
          })
        }

        window.open(
          `${routes.openWebApp.path}?token=${this.state.token}`,
          '_blank'
        )
      }
    } catch (error) {
      const { message } = error
      console.log({ message })
    }
    this.setState({ ui: { ...ui, isTeamCreationUpdateLoading: false } })
  }

  onCheckUsername = async (params: IUsernameCheckApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isSignUpLoading: true } })
    try {
      const response = await checkUsernameApi(params)
      const { message, status } = response
      if (status) {
        this.onSingUp(params)
      } else {
        errorToast(message)
        this.setState({
          ui: { ...ui, isSignUpLoading: false },
        })
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isSignUpLoading: false } })
    }
  }

  get planDays() {
    const {
      state: { userData },
    } = this
    const days = userData?.plan?.inTrialDays
      ? userData?.plan?.remaining_days
      : userData?.plan?.remaining_days || 0
    // trial_period_days

    return days
  }

  redirectedOrNot = () => {
    const {
      state: { userData, token },
    } = this
    return userData && token
  }

  teamIdOrNot = () => {
    const {
      state: { userData },
    } = this
    return userData?.teamId
  }

  setUserData = data => {
    const newState = {
      ...this.state,
      userData: {
        ...this.state.userData,
        ...data,
      },
      token: data?.token ? data.token : this.state.token,
    }
    this.setState(newState)
  }

  updateSessionIfNecessary = async (tokenParam?: string) => {
    try {
      const cookieToken = getCookie('token')
      const { token: queryParamToken,  promo_code: promoCode } = parseQueryString(history.location.search)

      const token = tokenParam || cookieToken || queryParamToken

      if (!this.state.userData && token) {
        const { id } = jwtDecode(token)
        const { sessionData } = await getSessionApi(id, token)

        const planName = sessionData.plan && sessionData.plan.metadata && sessionData.plan.metadata.NAME
        const isPaidPlan = planName === "FREELANCER" || planName === "STARTUP";

        if (sessionData.plan) {
          sessionData.plan.isPaid = isPaidPlan
        }

        this.setState({ userData: { ...sessionData, token }, token })
      } else if (!token) {
        clearState()
      }

      if (promoCode) {
        this.rootStore.BillingStore.setState({ promoCode })

        if (this.state.userData) {
          history.replace({
            pathname: routes.billing.path
          })
        }
      }
    } catch (error) {
      console.log('Error in updateSessionIfNecessary:\n', error)
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }

    this.state?.userData && setState('userData', this.state.userData)
    this.state?.userData?.token && setState('token', this.state.userData.token)
  }

  @action
  reset = () => {
    this.state = initialState
    clearState()
  }
}

export default AuthStore
