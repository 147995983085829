import ApiRequestServiceV2 from 'services/api.service.v2'
import { CONFIG } from 'config/config.env'
import { auth_header } from 'services/utils'

const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export interface AddFriendViaInviteLinkApiBody {
  email: string;
  isExistingUser: boolean;
  username?: string;
  realName?: string;
}

export interface AddFriendViaInviteLinkApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
    invitingUserId: number,
    inviteeUserId: number,
  };
}

export const addFriendViaInviteLinkApi = (
  invitingUserId: string,
  body: AddFriendViaInviteLinkApiBody,
  authToken: string
): AddFriendViaInviteLinkApiResponse => {
  return apiRequestServiceV2.post(
    `v2/users/${invitingUserId}/friends/invite-link`,
    body,
    auth_header(authToken)
  )
}

export interface TeamAddMemberViaInviteLinkApiBody {
  email: string;
  isExistingUser: boolean;
  username?: string;
  realName?: string;
}

export interface TeamAddMemberViaInviteLinkApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
    teamId: number,
    inviteeUserId: number,
  };
}

export const teamAddMemberViaInviteLinkApi = (
  teamId: string,
  body: TeamAddMemberViaInviteLinkApiBody,
  authToken: string
): TeamAddMemberViaInviteLinkApiResponse => {
  return apiRequestServiceV2.post(
    `v2/teams/${teamId}/members/invite-link`,
    body,
    auth_header(authToken)
  )
}

export interface GetTokenForFriendInviteLinkApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    token: string,
  };
}

export const getTokenForFriendInviteLinkApi = (
  userId: number,
  secret: string
): GetTokenForFriendInviteLinkApiResponse => {
  return apiRequestServiceV2.get(
    `v2/users/${userId}/invite-link-token?secret=${secret}`
  )
}

export interface GroupAddMemberViaInviteLinkApiBody {
  email: string;
  isExistingUser: boolean;
  username?: string;
  realName?: string;
}

export interface GroupAddMemberViaInviteLinkApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
    groupId: number,
    inviteeUserId: number,
  };
}

export const groupAddMemberViaInviteLinkApi = (
  groupId: string,
  body: GroupAddMemberViaInviteLinkApiBody,
  authToken: string
): GroupAddMemberViaInviteLinkApiResponse => {
  return apiRequestServiceV2.post(
    `v2/groups/${groupId}/members/invite-link`,
    body,
    auth_header(authToken)
  )
}
