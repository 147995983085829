let CONFIG = {}

switch (process.env.REACT_APP_NODE_ENV) {
  case 'test':
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL_TEST,
      MAC_BUILD: process.env.REACT_APP_MAC_BUILD_TEST,
      WIN_BUILD: process.env.REACT_APP_WIN_BUILD_TEST,
      STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY_TEST,
      SERVICEBOT_ID: process.env.REACT_APP_SERVICEBOT_ID_TEST,
      ANDROID_BUILD: process.env.REACT_APP_ANDROID_BUILD_TEST,
      IOS_BUILD: process.env.REACT_APP_IOS_BUILD_TEST,
      DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL_TEST,
      SHORTENING_SERVICE_URL: process.env.REACT_APP_SHORTENING_SERVICE_URL,
      isDevelopment: false,
      isProduction: false,
    }
    break
  case 'production':
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL_PRODUCTION,
      MAC_BUILD: process.env.REACT_APP_MAC_BUILD_PRODUCTION,
      WIN_BUILD: process.env.REACT_APP_WIN_BUILD_PRODUCTION,
      STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY_PRODUCTION,
      SERVICEBOT_ID: process.env.REACT_APP_SERVICEBOT_ID_PRODUCTION,
      ANDROID_BUILD: process.env.REACT_APP_ANDROID_BUILD_PRODUCTION,
      IOS_BUILD: process.env.REACT_APP_IOS_BUILD_PRODUCTION,
      DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL_PRODUCTION,
      SHORTENING_SERVICE_URL: process.env.REACT_APP_SHORTENING_SERVICE_URL,
      isDevelopment: false,
      isProduction: true,
    }
    break
  case 'stagging':
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL_STAGGING,
      MAC_BUILD: process.env.REACT_APP_MAC_BUILD_PRODUCTION,
      WIN_BUILD: process.env.REACT_APP_WIN_BUILD_PRODUCTION,
      STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY_PRODUCTION,
      SERVICEBOT_ID: process.env.REACT_APP_SERVICEBOT_ID_PRODUCTION,
      ANDROID_BUILD: process.env.REACT_APP_ANDROID_BUILD_PRODUCTION,
      IOS_BUILD: process.env.REACT_APP_IOS_BUILD_PRODUCTION,
      DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL_PRODUCTION,
      SHORTENING_SERVICE_URL: process.env.REACT_APP_SHORTENING_SERVICE_URL,
      isDevelopment: false,
      isProduction: true,
    }
    break
  case 'development':
  default:
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL_QA,
      MAC_BUILD: process.env.REACT_APP_MAC_BUILD_TEST,
      WIN_BUILD: process.env.REACT_APP_WIN_BUILD_TEST,
      STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY_TEST,
      SERVICEBOT_ID: process.env.REACT_APP_SERVICEBOT_ID_TEST,
      ANDROID_BUILD: process.env.REACT_APP_ANDROID_BUILD_TEST,
      IOS_BUILD: process.env.REACT_APP_IOS_BUILD_TEST,
      DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL_DEVELOPMENT,
      SHORTENING_SERVICE_URL: process.env.REACT_APP_SHORTENING_SERVICE_URL,
      isDevelopment: false,
      isProduction: false,
    }
    break
}


CONFIG['INVITE_LINK_TOKEN_FETCH_SECRET'] = process.env.REACT_APP_INVITE_LINK_TOKEN_FETCH_SECRET
CONFIG['WIN_BUILD_BETA'] = process.env.REACT_APP_WIN_BUILD_BETA
CONFIG['MAC_BUILD_BETA'] = process.env.REACT_APP_MAC_BUILD_BETA
CONFIG['SENTRY_DSN'] = process.env.REACT_APP_SENTRY_DSN
CONFIG.AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY
module.exports = { CONFIG }
