import React from 'react'
import { Route } from 'react-router-dom'
import { updateMetadata } from 'utils'

import Layout from 'containers/Layout/Layout'

export const StaticRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const {
        isStatic,
        pageName,
        AuthStore: { redirectedOrNot, teamIdOrNot },
      } = rest
      const resp = redirectedOrNot()
      const teamIdResp = teamIdOrNot()
      updateMetadata(pageName)

      if (isStatic && resp && teamIdResp) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      } else {
        return <Component {...props} isAuthenticated={false} />
      }
    }}
  />
)
