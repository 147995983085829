import React, { Component } from 'react'
import { Router, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { history, routes } from 'config/routes'

import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { StaticRoute } from './StaticRoute'

@inject(stores => {
  const { AuthStore } = stores?.rootStore
  return {
    AuthStore,
  }
})
@observer
class Routes extends Component {
  render() {
    const { AuthStore } = this.props

    let prevPath = null;

    // listen and notify Segment of client-side page updates
    history.listen((location) => {
      if (location.pathname !== prevPath) {
        prevPath = location.pathname;
        window.analytics.page({
          id: AuthStore?.state?.userData?.id,
          user_id: AuthStore?.state?.userData?.id, 
          username: AuthStore?.state?.userData?.username, 
          realName: AuthStore?.state?.userData?.realName, 
          email: AuthStore?.state?.userData?.email,
          phone: AuthStore?.state?.userData?.phone
        });
      }
    });

    return (
      <Router history={history}>
        <Switch>
          {Object.keys(routes).map(key => {
            const {
              component,
              name: pageName,
              exact,
              path,
              private: privateRoute,
              public: publicRoute,
              isStatic,
              teamIdBased,
            } = routes[key]
            const props = {
              key,
              path,
              exact,
              component,
              private: privateRoute,
              public: publicRoute,
              isStatic,
              teamIdBased,
              pageName,
              AuthStore,
            }
            return privateRoute ? (
              <PrivateRoute {...props} />
            ) : publicRoute ? (
              <PublicRoute {...props} />
            ) : (
              <StaticRoute {...props} />
            )
          })}
        </Switch>
      </Router>
    )
  }
}

export default Routes
