import { routes } from 'config/routes'

export const roleTypes = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  user: 'user',
  default: 'default',
}

// export const adminNotPermissionToAccess = [
//   routes.billing.path,
//   routes.billingHistory.path,
//   routes.upgradeplan.path,
//   routes.switchPlan.path,
//   routes.trialOver.path,
// ]

export const userNotPermissionToAccess = [
  routes.settings.path,
  routes.teamsetup.path,
  routes.billing.path,
]

export const checkRolePermissions = ({ path, role = roleTypes.user }) => {
  return role === roleTypes.user && userNotPermissionToAccess.includes(path)
}
