import { observable, action } from 'mobx'

export type IInitialState = {
  ui: {},
}

export const initialState: IInitialState = {
  ui: {},
}

class AppStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default AppStore
