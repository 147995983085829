import React from 'react'
import { toJS } from 'mobx'
import { Route, Redirect } from 'react-router-dom'
import { routes as routePaths } from 'config/routes'
import { checkRolePermissions } from 'config/roles'
import { updateMetadata } from 'utils'

import Layout from 'containers/Layout/Layout'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const {
        pageName,
        teamIdBased,
        path,
        AuthStore: { teamIdOrNot, state },
      } = rest
      const userData = toJS(state.userData)

      const teamIdResp = teamIdOrNot()
      const isCheckAccessPermission = checkRolePermissions({
        path,
        role: state?.userData?.role,
      })

      updateMetadata(pageName)

      if (userData) {
        if (teamIdBased) {
          if (teamIdResp) {
            if (!isCheckAccessPermission) {
              return (
                <Layout>
                  <Component {...props} />
                </Layout>
              )
            } else {
              return (
                <Redirect
                  to={{
                    pathname: routePaths.myteamsearchadd.path,
                    state: { from: props.location },
                  }}
                />
              )
            }
          } else {
            return (
              <Redirect
                to={{
                  pathname: routePaths.teamsetup.path,
                  state: { from: props.location },
                }}
              />
            )
          }
        } else {
          return <Component {...props} />
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: routePaths.signin.path,
              state: { from: props.location },
            }}
          />
        )
      }
    }}
  />
)
