import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import * as Sentry from '@sentry/browser'

import 'index.scss'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import rootStore from 'stores'
import { CONFIG } from 'config/config.env'

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import amplitude from 'amplitude-js';

if (CONFIG.isProduction) {
  amplitude.getInstance().init(CONFIG.AMPLITUDE_API_KEY);
  Sentry.init({ dsn: CONFIG.SENTRY_DSN })
}


ReactDOM.render(
  <Fragment>
    <Provider rootStore={rootStore}>
      <App />
    </Provider>
    <ToastContainer autoClose={2000} position={'top-right'} />
  </Fragment>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
