import { observable, action } from 'mobx'
import {
  ITeamData,
  getTeamDetailsApi,
  IUpdateTeamDetailsApiParams,
  IGetTeamDetailsApiParams,
  updateTeamDetailsApi,
} from 'services/settings'
import { errorToast } from 'utils'

export type IInitialState = {
  data: ITeamData,
  ui: {
    isSettingsDetailsLoading: boolean,
    isSettingsFormSubmitting: boolean,
    isOpenCancelPlanModal: boolean,
    isCancelPlanFormSubmitting: boolean,
    isOpenDeleteAccountModal: boolean,
    isDeleteAccountFormSubmitting: boolean,
  },
}

export const initialState: IInitialState = {
  data: {},
  ui: {
    isSettingsDetailsLoading: false,
    isSettingsFormSubmitting: false,
    isOpenCancelPlanModal: false,
    isCancelPlanFormSubmitting: false,
    isOpenDeleteAccountModal: false,
    isDeleteAccountFormSubmitting: false,
  },
}

class SettingsStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  onGetTeamDetails = async (params: IGetTeamDetailsApiParams) => {
    const { ui } = this.state
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    this.setState({ ui: { ...ui, isSettingsDetailsLoading: true } })
    try {
      const response = await getTeamDetailsApi(params)
      const { message, status, data } = response
      if (status) {
        this.setState({ data })
        const { name, teamImageUrl } = data
        let updateUser = {
          ...userData,
          teamName: name,
        }
        if (teamImageUrl) {
          updateUser = {
            ...updateUser,
            teamImageUrl,
          }
        }
        await AuthStore.setUserData(updateUser)
      } else {
        errorToast(message)
      }
      this.setState({
        ui: { ...ui, isSettingsDetailsLoading: false },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isSettingsDetailsLoading: false } })
    }
  }

  onUpdateTeamDetails = async (params: IUpdateTeamDetailsApiParams) => {
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    const { ui } = this.state
    this.setState({ ui: { ...ui, isSettingsFormSubmitting: true } })
    try {
      const response = await updateTeamDetailsApi(params)
      const { message, status, data } = response
      if (status) {
        this.setState({ data })
        const { name, teamImageUrl } = data
        let updateUser = {
          ...userData,
          teamName: name,
        }
        if (teamImageUrl) {
          updateUser = {
            ...updateUser,
            teamImageUrl,
          }
        }
        await AuthStore.setUserData(updateUser)
        this.setState({
          ui: { ...ui, isSettingsFormSubmitting: false },
        })
        return Promise.resolve(true)
      } else {
        errorToast(message)
        this.setState({
          ui: { ...ui, isSettingsFormSubmitting: false },
        })
        return Promise.resolve(false)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isSettingsFormSubmitting: false } })
      return Promise.resolve(false)
    }
  }

  onHandleCancelPlanModal = () => {
    const { ui } = this.state
    const { isOpenCancelPlanModal } = ui
    this.setState({
      ui: { ...ui, isOpenCancelPlanModal: !isOpenCancelPlanModal },
    })
  }

  onHandleDeleteAccountModal = () => {
    const { ui } = this.state
    const { isOpenDeleteAccountModal } = ui
    this.setState({
      ui: { ...ui, isOpenDeleteAccountModal: !isOpenDeleteAccountModal },
    })
  }

  onCancelPlan = () => {}

  onDeleteAccount = () => {}

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default SettingsStore
