import React from 'react'
import cx from 'classnames'
import { images } from 'config/images'
import { roleTypes, checkRolePermissions } from 'config/roles'
import { PLAN_FREE } from 'common/constants'
import { inject, observer } from 'mobx-react'

import './NavigationLeftsideBar.scss'

const NavigationLeftsideBar = inject('rootStore')(
  observer(props => {
    const {
      sideBarMenu,
      onSideBarMenuSelect,
      activePath,
      teamName,
      teamLogo,
      currentPlan,
      isToggleMenu,
      userRole,
      onUpgradePlan,
      onAddSeats,
      disableAllBtns,
      planStatus,
      isHideUpgradeButton,
      alertBannerVisible,
      rootStore,
    } = props

    const { AuthStore } = rootStore
    const stripeCustomerId = AuthStore?.state?.userData?.stripeCustomerId

    const isToggleMenuClass =
      isToggleMenu && window.innerWidth < 768 ? true : false

    const planUpgradeButton = () => {
      return !isHideUpgradeButton &&
        currentPlan &&
        Object.keys(currentPlan).length ? (
        <div
          className={`Userinfo__right ${
            disableAllBtns ? 'disableMenuLists' : ''
          }`}
        >
          {userRole !== roleTypes.user &&
            (currentPlan?.nickname === PLAN_FREE ? (
              <div className="btn__upgrade" onClick={onUpgradePlan}>
                Upgrade
              </div>
            ) : (
              <div className="btn__upgrade" onClick={onAddSeats}>
                Upgrade
              </div>
            ))}
        </div>
      ) : null
    }

    return (
      <div
        className={`NavigationLeftsideBar__main${
          isToggleMenuClass ? ' mobfull__sidebar' : ''
        }${alertBannerVisible ? ' alert-banner-visible' : ''}`}
      >
        <div className="NavigationLeftsideBar__toppart">
          <div className="NavigationLeftsideBar__UserDetail">
            <div className="Userinfo__left">
              <div className="UserPic">
                <img
                  className={cx('menu-icon', {
                    'cls-default-team-logo': !teamLogo,
                  })}
                  src={teamLogo ? teamLogo : images.yaclogo}
                  alt="logo"
                />
              </div>
              <div className="UserInfo">
                <div className="UserTeam__name">{teamName}</div>
                {userRole !== roleTypes.user && planStatus && (
                  <div className="UserTeam__plan">
                    <label>{planStatus}</label>
                  </div>
                )}
              </div>
            </div>
            {planUpgradeButton()}
          </div>
          <div className="sidemenu">
            <ul
              className={`menulists ${
                disableAllBtns ? 'disableMenuLists' : ''
              }`}
            >
              {Object.keys(sideBarMenu).map(key => {
                if (!stripeCustomerId && key === 'billing') {
                  return null
                }
                const menu = sideBarMenu[key]
                const image =
                  menu.path === activePath ? menu.iconOne : menu.iconTwo
                const className =
                  menu.path === activePath ? 'menuitem active' : 'menuitem'
                const isCheckAccessPermission = checkRolePermissions({
                  path: menu.path,
                  role: userRole,
                })

                return !isCheckAccessPermission ? (
                  <li key={menu.id} className={className}>
                    <a onClick={() => onSideBarMenuSelect(menu.id)}>
                      <img
                        className={`menu-icon${
                          menu.id === 'openWebApp' ? ' open-web-app' : ''
                        }`}
                        src={image}
                        alt={menu.name}
                      />
                      <span>{menu.name}</span>
                    </a>
                  </li>
                ) : null
              })}
            </ul>
          </div>
        </div>
        <div className="NavigationLeftsideBar__logo">
          <img className="menu-icon" src={images.yaclogo} alt="Yac" />
        </div>
      </div>
    )
  })
)

export { NavigationLeftsideBar }
