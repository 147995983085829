import ApiRequestServiceV2 from 'services/api.service.v2'
import { CONFIG } from 'config/config.env'
import { auth_header } from 'services/utils'

const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export interface GroupGetDataByIdApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    name: string,
    image: string,
    createdByName: string,
  };
}

export function groupGetDataByIdV2Api(
  groupId: string
): GroupGetDataByIdApiResponse {
  return apiRequestServiceV2.get(`v2/groups/${groupId}/image-and-name`)
}

export interface CreateGroupApiParams {
  name: string;
  createdBy?: number;
  bio?: String;
}

export type CreateGroupApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: {
    id: number,
  },
}

export async function createGroupApi(
  params: CreateGroupApiParams
): CreateGroupApiResponse {
  const response = await apiRequestServiceV2.post(
    'v1/group/create',
    params,
    auth_header()
  )

  if (response.status) {
    return response.groupDetails
  } else {
    throw new Error(response.message)
  }
}

export interface UpdateGroupApiParams {
  name?: string;
  bio?: String;
}

export type UpdateGroupApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: {
    id: number,
  },
}

export async function updateGroupApi(
  groupId: number,
  params: UpdateGroupApiParams
): UpdateGroupApiResponse {
  const response = await apiRequestServiceV2.post(
    'v1/group/update',
    { groupId, ...params },
    auth_header()
  )

  if (response.status) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export interface GetGroupInviteLinkApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    name: string,
    image: string,
    createdByName: string,
  };
}

export function getGroupInviteLinkApi(
  groupId: number
): GetGroupInviteLinkApiResponse {
  return apiRequestServiceV2.get(
    `v2/groups/${groupId}/invite-link`,
    auth_header()
  )
}

export interface InviteGroupMembersApiParams {
  emails?: string[];
}
export interface InviteGroupMembersApiParamsApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {};
}

export function inviteGroupMembersApi(
  groupId: number,
  params: InviteGroupMembersApiParams
): GetGroupInviteLinkApiResponse {
  return apiRequestServiceV2.post(
    `v2/groups/${groupId}/members`,
    params,
    auth_header()
  )
}

interface GroupMember {
  userId: number;
  groupId: number;
  addedAt: string;
  updatedBy: number;
  updatedAt: string;
  inLiveChat: 0 | 1;
  liveChatMuted: 0 | 1;
  isDeleted?: 0 | 1;
  deletedAt?: string;
  deletedBy?: number;
  mutePushNotification?: 0 | 1;
}
export interface GetGroupMembersApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    groupMembers: GroupMember[]
  };
}

export function getGroupMembersApi(
  groupId: number,
): GetGroupMembersApiResponse {
  return apiRequestServiceV2.get(
    `v2/groups/${groupId}/members`,
    auth_header()
  )
}
