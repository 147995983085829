import { lazy } from 'react'
import { images } from 'config/images'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const routes = {
  home: {
    name: 'Setup, manage, and invite your team to Yac',
    path: '/',
    exact: true,
    public: true,
    component: lazy(() => import('pages/SignIn/SignInPage')),
  },
  signup: {
    name: 'Create Team',
    path: '/create',
    exact: true,
    public: true,
    component: lazy(() => import('pages/SignUp/SignUpPage')),
  },
  signin: {
    name: 'Setup, manage, and invite your team to Yac',
    path: '/manage',
    exact: true,
    public: true,
    component: lazy(() => import('pages/SignIn/SignInPage')),
  },
  emailconfirmation: {
    name: 'Email Confirmation',
    path: '/emailconfirmation',
    exact: true,
    public: true,
    component: lazy(() =>
      import('pages/EmailConfirmation/EmailConfirmationPage')
    ),
  },
  teamsetup: {
    name: 'Team SetUp',
    path: '/teamsetup',
    exact: true,
    private: true,
    component: lazy(() => import('pages/TeamSetUp/TeamSetUpPage')),
  },
  myteamsearchadd: {
    name: 'My Team',
    path: '/team',
    exact: true,
    private: true,
    teamIdBased: true,
    component: lazy(() => import('pages/Teams/TeamsPage')),
  },
  acceptInvitation: {
    name: 'Accept Invitation',
    path: '/accept-invitation/:token',
    exact: true,
    private: false,
    public: false,
    component: lazy(() =>
      import('pages/AcceptInvitation/AcceptInvitationPage')
    ),
  },
  thankYou: {
    name: 'Thank You',
    path: '/thank-you',
    exact: true,
    private: false,
    public: false,
    component: lazy(() => import('pages/ThankYou/ThankYouPage')),
  },
  teamMemberThankYou: {
    name: 'Thank You',
    path: '/thank-you/:teamName',
    exact: true,
    private: false,
    public: false,
    component: lazy(() => import('pages/ThankYou/ThankYouPage')),
  },
  settings: {
    name: 'Settings',
    path: '/settings',
    exact: true,
    private: true,
    public: false,
    teamIdBased: true,
    component: lazy(() => import('pages/Settings/SettingsPage')),
  },
  userProfile: {
    name: 'My Profile',
    path: '/profile',
    exact: true,
    private: true,
    public: false,
    teamIdBased: true,
    component: lazy(() => import('pages/UserProfile/UserProfilePage')),
  },
  downloadYac: {
    name: 'Download Yac',
    path: '/download',
    exact: true,
    isStatic: true,
    component: lazy(() => import('pages/DownloadYac/DownloadYacPage')),
  },
  termsOfService: {
    name: 'Terms And Conditions',
    path: '/terms',
    exact: true,
    isStatic: true,
    component: lazy(() => import('pages/TermsOfService/TermsOfServicePage')),
  },
  privacyAndPolicy: {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    exact: true,
    isStatic: true,
    component: lazy(() =>
      import('pages/PrivacyAndPolicy/PrivacyAndPolicyPage')
    ),
  },
  billing: {
    name: 'Billing',
    path: '/billing',
    private: true,
    exact: true,
    teamIdBased: true,
    component: lazy(() => import('pages/BillingPortal/BillingPortalPage')),
  },
  playMessage: {
    name: 'PlayMessage',
    path: '/messages/:messageId',
    exact: true,
    public: true,
    component: lazy(() => import('pages/PlayMessage/PlayMessagePage')),
  },
  openWebApp: {
    name: 'openWebApp',
    path: 'https://web.yac.com',
    exact: true,
    public: true,
  },
  integrations: {
    name: 'Integrations',
    path: '/integrations',
    exact: true,
    private: true,
    public: false,
    teamIdBased: true,
    component: lazy(() => import('pages/Integrations/IntegrationsPage')),
  },
  createMeeting: {
    name: 'Create Meeting',
    path: '/create-meeting',
    exact: true,
    private: false,
    public: true,
    teamIdBased: false,
    component: lazy(() => import('pages/CreateMeeting/CreateMeetingPage')),
  },
  // billing: {
  //   name: 'Billing',
  //   path: '/billing',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/BillingNew/BillingNew')),
  // },
  // billingHistory: {
  //   name: 'Billing History',
  //   path: '/billing-history',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/BillingHistory/BillingHistoryPage')),
  // },
  // upgradeplan: {
  //   name: 'Upgrade Plan',
  //   path: '/upgrade-plan',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/UpgradePlan/UpgradePlan')),
  // },
  // switchPlan: {
  //   name: 'Switch Plan',
  //   path: '/switch-plan',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/SwitchPlan/SwitchPlan')),
  // },
  // trialOver: {
  //   name: 'Trial Over',
  //   path: '/trial-over',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/TrialOver/TrialOver')),
  // },
  // plans: {
  //   name: 'Plans',
  //   path: '/plans',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/Plans/PlansPage')),
  // },
  // purchasePlan: {
  //   name: 'Purchase Plan',
  //   path: '/plans/:planId',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/PurchasePlan/PurchasePlanPage')),
  // },
  // changePlan: {
  //   name: 'Change Plan',
  //   path: '/change-plan',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/ChangePlan/ChangePlanPage')),
  // },
  // changePlanForm: {
  //   name: 'Change Plan',
  //   path: '/change-plan/:planId',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  // component: lazy(() => import('pages/ChangePlanForm/ChangePlanFormPage')),
  // },
  // planUpgraded: {
  //   name: 'Plan Upgraded',
  //   path: '/plan-upgraded',
  //   exact: true,
  //   private: true,
  //   public: false,
  //   teamIdBased: true,
  //   component: lazy(() => import('pages/PlanUpgraded/PlanUpgradedPage')),
  // },
  // billingnew: {
  //   name: 'Billing New',
  //   path: '/billing-new',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/BillingNew/BillingNew')),
  // },
  // signinnew: {
  //   name: 'Sign In',
  //   path: '/sign-in',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/SignInV1/SignIn')),
  // },
  // signupnew: {
  //   name: 'Sign Up',
  //   path: '/sign-up',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/SignUpV1/SignUp')),
  // },
  // welcome: {
  //   name: 'Welcome',
  //   path: '/wel-come',
  //   exact: true,
  //   isStatic: true,
  //   component: lazy(() => import('pages/Welcome/Welcome')),
  // },
  // emailunsubscribe: {
  //   name: 'Email Unsubscribe',
  //   path: '/email-unsubscribe/:serviceName/:unSubscriberCipher',
  //   exact: true,
  //   isStatic: true,
  //   private: false,
  //   component: lazy(() => import('pages/EmailUnsubscribe/EmailUnsubscribe')),
  // },
  errorpage: {
    name: 'Not Found',
    path: '/',
    component: lazy(() => import('containers/ErrorPage/ErrorPage')),
  },
}

export const sideBarMenu = {
  userProfile: {
    id: 'userProfile',
    name: 'My Profile',
    path: routes.userProfile.path,
    iconOne: images.ico_profile_active,
    iconTwo: images.ico_profile,
  },
  myTeam: {
    id: 'myTeam',
    name: 'My Team',
    path: routes.myteamsearchadd.path,
    iconOne: images.ico_myteam_active,
    iconTwo: images.ico_myteam,
  },
  billing: {
    id: 'billing',
    name: 'Billing',
    // childRoutes: [
    //   '/billing-history',
    //   '/upgrade-plan',
    //   '/billing',
    //   '/switch-plan',
    //   '/trial-over',
    // ],
    path: routes.billing.path,
    iconOne: images.ico_billing_active,
    iconTwo: images.ico_billing,
  },
  integrations: {
    id: 'integrations',
    name: 'Integrations',
    path: routes.integrations.path,
    iconOne: images.ico_integrations_active,
    iconTwo: images.ico_integrations,
  },
  settings: {
    id: 'settings',
    name: 'Settings',
    path: routes.settings.path,
    iconOne: images.ico_setting_active,
    iconTwo: images.ico_setting,
  },
  openWebApp: {
    id: 'openWebApp',
    name: 'Open Web App',
    path: routes.openWebApp.path,
    iconOne: images.ico_download_active,
    iconTwo: images.ico_download,
  },
  signOut: {
    id: 'signOut',
    name: 'Logout',
    path: routes.home.path,
    iconOne: images.ico_logout_active,
    iconTwo: images.ico_logout,
  },
}
