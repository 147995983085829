export const DEFAULT_FILE_LIMIT_IN_MB = 30
export const DEFAULT_PAGE_RANGE_DISPLAY = 5
export const DEFAULT_PAGINATION_LIMIT = 10
export const EMAIL_SIZE_LIMIT = 45
export const PER_TEAM_DOLLAR_PRICE = 10
export const PER_TEAM_DOLLAR_PRICE_ANNUALLY = 8

export const NAME_REG_EXP = /^[a-zA-Z0-9 À-ÖØ-öø-ÿ ,.'-]{3,30}$/
export const USERNAME_REG_EXP = /^[a-zA-Z0-9#_.-]{2,30}$/
export const TEAM_REG_EXP = /^[a-zA-Z0-9 ._+#'-]{3,30}$/
export const TEAM_SEARCH_STRING_REG_EXP = /^[a-zA-Z0-9@# À-ÖØ-öø-ÿ]{1,30}$/
export const SPECIAL_CHARACTERS_REG_EXP = /[^A-Za-z0-9_-\s]/g
export const EMAIL_REG_EXP = /^[a-zA-Z0-9@#_.-]{3,45}$/
export const EMAIL_REG_EXP_V2 = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const PHONE_REG_EXP = /\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
export const EMAIL_OR_PHONE_REG_EXP = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
export const GROUP_NAME_REG_EXP = /^[a-zA-Z0-9_-]+$/
export const PLAN_MONTHLY = 'Monthly'
export const PLAN_YEARLY = 'Yearly'
export const PLAN_ANNUALLY = 'Annually'
export const PLAN_FREE = 'Personal' // Free
export const PLAN_PRO = 'Pro Plan'
export const ACTIVE_MEMBERS = 5
export const FREELANCER = 'FREELANCER'
export const STARTUP_CAPS = 'STARTUP'
export const STARTUP = 'Startup'
export const BASE = 'BASE'

export const ALLOWED_FEATURES = {
  PRIVATE_TEAMS: 'PRIVATE_TEAMS',
  TRANSCRIPTS: 'TRANSCRIPTS',
  INTEGRATIONS: 'INTEGRATIONS',
  GROUPS: 'GROUPS',
}

export const CARD_BRANDS = {
  visa: 'pf-visa',
  mastercard: 'pf-mastercard',
  amex: 'pf-american-express',
  discover: 'pf-discover',
  diners: 'pf-diners',
  jcb: 'pf-jcb',
  unknown: 'pf-credit-card',
}

export const ENTITY_TYPES = {
  user: 'user',
  team: 'team',
  channel: 'channel',
  privateGroup: 'private_group'
}