import { observable, action } from 'mobx'
import {
  getFriendsApi,
  removeFriendApi,
  Friend,
  addFriendApi,
} from 'services/friends'
import { errorToast } from 'utils'

export interface InitialState {
  data: {
    friends: Friend[],
  };
}

export const initialState: InitialState = {
  data: {
    friends: [],
  },
}

class FriendsStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  @action.bound
  getFriends = async userId => {
    try {
      const { data } = this.state

      const { friends } = await getFriendsApi(userId)

      this.setState({ data: { ...data, friends } })
    } catch (error) {
      console.log(`Error in FriendsStore.getFriends.\nError:\n`, error)

      throw error
    }
  }

  @action.bound
  addFriend = async (userId, emailOrUsername) => {
    try {
      const isEmail = !!emailOrUsername.match(/\w{1,}@\w{1,}\.\w{1,}/)

      const body = isEmail
        ? { email: emailOrUsername, resendInvite: true }
        : { username: emailOrUsername, resendInvite: true }

      await addFriendApi(userId, body)
    } catch (error) {
      console.log(`Error in FriendsStore.addFriend.\nError:\n`, error)

      throw error
    }
  }

  @action.bound
  removeFriend = async (userId, friendId) => {
    try {
      await removeFriendApi(userId, friendId)
    } catch (error) {
      console.log(`Error in FriendsStore.removeFriend.\nError:\n`, error)

      throw error
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default FriendsStore
