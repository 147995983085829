export const images = {
  YAC_logo: require('assets/images/logo.svg'),
  ico_myteam: require('assets/images/ico_myteam.svg'),
  ico_billing: require('assets/images/ico_billing.svg'),
  ico_billing_active: require('assets/images/ico_billing_active.svg'),
  ico_setting_active: require('assets/images/ico_setting_active.svg'),
  ico_setting: require('assets/images/ico_setting.svg'),
  ico_plans: require('assets/images/ico_plans.svg'),
  ico_logout: require('assets/images/ico_logout.svg'),
  ico_logout_active: require('assets/images/ico_logout_active.svg'),
  ico_download: require('assets/images/ico_download_yac.svg'),
  ico_download_active: require('assets/images/ico_download_yac_active.svg'),
  user__hunter: require('assets/images/hunter.png'),
  ico_copy: require('assets/images/ico_copy.svg'),
  user_placeholder: require('assets/images/user_placeholder.jpg'),
  ico_myteam_active: require('assets/images/ico_myteam_active.svg'),
  ico_menu: require('assets/images/ico_menu.svg'),
  ico_close: require('assets/images/ico_close.svg'),
  ico_close_black: require('assets/images/ico_close_black.svg'),
  ico_loader: require('assets/images/loader.gif'),
  ico_play: require('assets/images/ico_play.svg'),
  img_congratulation: require('assets/images/img_congratulation@2x.png'),
  YAC_logo__black: require('assets/images/YAC_logo__black.svg'),
  YAC_logo_yellow_black: require('assets/images/yacblack.png'),
  ico_arrow: require('assets/images/arrow@2x.png'),
  ico_add: require('assets/images/add@3x.png'),
  ico_add_2x: require('assets/images/add@2x.png'),
  ico_add_two: require('assets/images/add@2x.png'),
  add_image_icon_new: require('assets/images/add_img_icon.svg'),
  default_team_logo: require('assets/images/yacchat_default_team_logo.jpg'),
  ico_nodatafound: require('assets/images/ico_nodatafound.svg'),
  ico_nointernent: require('assets/images/ico_no-internent.svg'),
  ico_404: require('assets/images/ico_404.svg'),
  ico_error_red: require('assets/images/ico_error_red.svg'),
  ico_profile: require('assets/images/ico_profile.svg'),
  ico_profile_active: require('assets/images/ico_profile_active.svg'),
  imglaptop: require('assets/images/img_laptop2.png'),
  imgmobile: require('assets/images/img_mobile2.png'),
  imgplaystore: require('assets/images/img_playstore.png'),
  imgappstore: require('assets/images/img_appstore.svg'),
  logo_spotify: require('assets/images/logo_spotify.svg'),
  logo_hubspot: require('assets/images/logo_hubspot.svg'),
  logo_cbs: require('assets/images/logo_cbs.svg'),
  logo_rev: require('assets/images/logo_rev.svg'),
  logo_dialpad: require('assets/images/logo_dialpad.png'),
  illu_unsubscribe: require('assets/images/illu_unsubscribe.svg'),
  ico_card_visa: require('assets/images/ico_card_visa.png'),
  ico_mastercard: require('assets/images/ico_mastercard.svg'),
  ico_american_express: require('assets/images/ico_american-express.svg'),
  yaclogo: require('assets/images/yaclogo.svg'),
  yacLogoTeamFallback: require('assets/images/logo_black-on-white-yellow-accent.svg'),
  upload__pic: require('assets/images/upload.svg'),
  ico_integrations: require('assets/images/icon-integration.svg'),
  ico_integrations_active: require('assets/images/icon-integration-active.svg'),
  ico_slack: require('assets/images/slack-mark.svg'),
  ico_zapier: require('assets/images/zapier-icon.svg'),
  ico_send_right: require('assets/images/icon-send-right.svg'),
  mic_record: require('assets/images/mic-record.svg'),
  ico_send_left: require('assets/images/icon-send-left.svg'),
  optional_settings_white: require('assets/images/optional-settings-white.svg'),
  optional_settings_yellow: require('assets/images/optional-settings-yellow.svg'),
  optional_settings_black: require('assets/images/optional-settings-black.svg'),
  checkmark_black: require('assets/images/icon-check-single.svg'),
  magnifying_glass_black: require('assets/images/icon-search.svg'),
  meeting_links_image: require("assets/images/meeting_links_image.png"),
  meeting_links_image2x: require("assets/images/meeting_links_image@2x.png"),
  meeting_links_image3x: require("assets/images/meeting_links_image@3x.png"),
  meeting_link_image: require("assets/images/meeting-link-image.png"),
  meeting_link_image2x: require("assets/images/meeting-link-image@2x.png"),
  meeting_link_image3x: require("assets/images/meeting-link-image@3x.png"),
  meeting_links_image_phone: require("assets/images/mobile_meeting_link.png"),
  meeting_links_image_phone2x: require("assets/images/mobile_meeting_link@2x.png"),
  meeting_links_image_phone3x: require("assets/images/mobile_meeting_link@3x.png")
}
