import ApiRequestServiceV2 from 'services/api.service.v2'
import { auth_header } from 'services/utils'
import { CONFIG } from 'config/config.env'

const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export interface Friend {
  id: number;
  username: string;
  realName: string;
  image: string;
  isActive: 0 | 1;
}

export interface GetFriendsApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    friends: Friend[],
  };
}

export const getFriendsApi = (userId: string): GetFriendsApiResponse => {
  return apiRequestServiceV2.get(`v2/users/${userId}/friends`, auth_header())
}

export interface AddFriendApiBody {
  email?: string;
  username?: string;
  resendInvite?: boolean;
}

export interface AddFriendApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
  };
}

export const addFriendApi = (
  userId: string,
  body: AddFriendApiBody
): AddFriendApiResponse => {
  return apiRequestServiceV2.post(
    `v2/users/${userId}/friends`,
    body,
    auth_header()
  )
}

export interface RemoveFriendApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
  };
}

export const removeFriendApi = (
  userId: string,
  friendId: string
): RemoveFriendApiResponse => {
  return apiRequestServiceV2.delete(
    `v2/users/${userId}/friends/${friendId}`,
    auth_header()
  )
}
