import ApiRequestService from 'services/api.service'
import objectToFormData from 'object-to-formdata'
import { content_types, auth_header } from 'services/utils'
import ApiRequestServiceV2 from 'services/api.service.v2'
import { CONFIG } from 'config/config.env'

const api = new ApiRequestService()
const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export type IUserData = {
  username: string,
  realName: string,
  email: string,
  image: string,
}

/* get user profile details| start */
export type IGetUserProfileApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IUserData,
}

export const getUserProfileApi = (
  token?: string
): IGetUserProfileApiResponse => {
  return api.getApi(`v1/users/userDetails`, {
    ...auth_header(token),
  })
}
/* get user profile details | end */

/* update user profile | start */
export type IUpdateUserProfileApiParams = IUserData & {
  key?: string,
  file?: string,
}

export type IUpdateUserProfileApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IUserData,
}

export const updateUserProfileApi = (
  params: IUpdateUserProfileApiParams
): IUpdateUserProfileApiResponse => {
  const data = objectToFormData(params)
  return api.putApi('v1/users/update', data, {
    ...content_types.multipart,
    ...auth_header(),
  })
}
/* update user profile details | end */

/* update user email | start */
export type IUpdateUserEmailApiResponse = {
  message: string,
}

export const updateUserEmailApi = (
  userId: string,
  email: string
): IUpdateUserEmailApiResponse => {
  return apiRequestServiceV2.patch(
    `v2/users/${userId}/email`,
    { email },
    auth_header()
  )
}

export type IConfirmUpdateUserEmailApiResponse = {
  message: string,
}

export const confirmUpdateUserEmailApi = (
  userId: string,
  confirmationCode: number
): IConfirmUpdateUserEmailApiResponse => {
  return apiRequestServiceV2.patch(
    `v2/users/${userId}/email/confirmation`,
    { confirmationCode },
    auth_header()
  )
}
/* update user email | end */

export const unsubscribeEmail = ({ serviceName, unSubscriberCipher }) => {
  return api.getApi(`v1/unSubscribe/${serviceName}/${unSubscriberCipher}`)
}

export interface UserGetImageAndNameViaUsernameResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    id: number,
    username: string,
    image: string,
    name: string,
  };
}

export const userGetDataViaUsernameV2Api = (
  username: string,
  userId?: number
): UserGetImageAndNameViaUsernameResponse => {
  return apiRequestServiceV2.get(
    `v2/users/${username}/image-and-name${userId ? `?userId=${userId}` : ''}`
  )
}

export interface GetSessionApiResponse {
  sessionData: Record<string, any>;
}

export const getSessionApi = (
  userId: number,
  token?: string
): GetSessionApiResponse => {
  return apiRequestServiceV2.get(
    `v2/users/${userId}/session`,
    auth_header(token)
  )
}
