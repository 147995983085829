import { CONFIG } from "../config/config.env"

export const getState = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key)
    if (!serializedState) {
      return undefined
    }
    return key === 'token' ? serializedState : JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const setState = (key: string, data: any) => {
  try {
    const serializedState = key === 'token' ? data : JSON.stringify(data)

    localStorage.setItem(key, serializedState)

    if (key === 'token' && !getCookie('token')) {
      document.cookie = `token=${data};${CONFIG.isProduction ? "domain=.yac.com;" : ""}path=/`
    }
  } catch {
    // ignore write errors
  }
}

export const removeState = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch {
    // ignore write errors
  }
}

export const clearState = () => {
  localStorage.clear()
  document.cookie = `token=;${CONFIG.isProduction ? "domain=.yac.com;" : ""}expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
}

export const getCookie = cname => {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
