import ApiRequestService from 'services/api.service'
import { auth_header } from 'services/utils'

const api = new ApiRequestService()

export type IPlansData = {
  id: string,
  aggregate_usage: string,
  amount: number,
  amount_decimal: string,
  billing_scheme: string,
  created: number,
  currency: string,
  interval: string,
  interval_count: number,
  livemode: boolean,
  metadata: object,
  nickname: string,
  object: string,
  product: string,
  tiers: string,
  tiers_mode: string,
  transform_usage: string,
  trial_period_days: number,
  usage_type: string,
  active: boolean,
}

export type IStandardPlanForm = {
  id: string,
  aggregate_usage: string,
}

export type ICountries = {
  value: string,
  label: string,
}

export type IStates = {
  value: string,
  label: string,
}

/* get plans list | start */
export type IGetPlansApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: {
    plans: IPlansData[],
    teamSize: number,
  },
}

export const getPlansApi = (): IGetPlansApiResponse => {
  return api.getApi(`v1/billing/plan/list`, {
    ...auth_header(),
  })
}
/* get plans list | end */

/* get select plan details | start */
export type IGetSelectPlanApiParams = {
  planId: string,
}

export type IGetSelectPlanApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IPlansData,
}

export const getSelectPlanApi = (
  params: IGetSelectPlanApiParams
): IGetSelectPlanApiResponse => {
  const { planId } = params
  return api.getApi(`v1/billing/plan-details/${planId}`, {
    ...auth_header(),
  })
}
/* get select plan details | end */

/* check plan promo-code | start */
export type ICheckPlanPromoCodeApiParams = {
  couponCode: string,
  planId: Number,
}

export type ICheckPlanPromoCodeApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IPlansData,
}

export const checkPromoCodeApi = (
  params: ICheckPlanPromoCodeApiParams
): ICheckPlanPromoCodeApiResponse => {
  const { couponCode, planId } = params
  return api.getApi(`v1/billing/coupon-details/${couponCode}/${planId}`, {
    ...auth_header(),
    // params,
  })
}
/* check plan promo-code | end */

/* purchase standard plan (monthly, yearly) | start */
export type IPurchaseStandardPlanApiParams = {
  planId: string,
  paymentToken?: string,
  address1: string,
  address2?: string,
  country: string,
  state: string,
  city: string,
  zipCode: string,
  couponCode?: string,
  fullNameOnCard: string,
}

export type IPurchaseStandardPlanApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const purchaseStandardPlanApi = (
  params: IPurchaseStandardPlanApiParams
): IPurchaseStandardPlanApiResponse => {
  return api.postApi('v1/billing', params, {
    ...auth_header(),
  })
}
/* purchase standard plan (monthly, yearly) | end */

/* get subscribed plan details | start */
export type IGetSubscribedPlansDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IPlansData,
}

export const getSubscribedPlanDetailsApi = (): IGetSubscribedPlansDetailsApiResponse => {
  return api.getApi(`v1/billing/subscribed/plan`, {
    ...auth_header(),
  })
}
/* get subscribed plan details | end */

export type ICurrentPlanData = {
  teamSize: Number,
  lastPayment: String,
  nextPayment: String,
  amount: Number,
}

/* get payment information | start */
export type IGetCurrentPlanPaymentDetailsResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ICurrentPlanData,
}

export const getCurrentPlanDetailsApi = async (): IGetCurrentPlanPaymentDetailsResponse => {
  return api.getApi(`v1/billing/current-plan`, {
    ...auth_header(),
  })
}
/* get payment information | end */
