import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

import SideBarMenu from 'containers/SideBarMenu/SideBarMenu'
import AlertBanner from 'components/AlertBanner/AlertBanner'
import 'containers/Layout/Layout.scss'

@inject(stores => {
  const { BillingStore, AuthStore, BaseTeamStore } = stores?.rootStore
  return {
    BillingStore,
    AuthStore,
    BaseTeamStore,
  }
})
@observer
class Layout extends React.Component {
  constructor(props) {
    super(props)

    let  alertBannerVisible = false

    const promoCode = this.props.BillingStore.state.promoCode && toJS(this.props.BillingStore.state.promoCode)
    const plan = this.props.AuthStore.state.userData && toJS(this.props.AuthStore.state.userData.plan)

    if (!promoCode && plan && !plan.isPaid) {
      alertBannerVisible = true
    }

    this.state = {
      alertBannerVisible
    }
  }

  async componentDidMount() {
    await this.props.BillingStore.onGetBillingPlanDetails()

    const promoCode = this.props.BillingStore.state.promoCode && toJS(this.props.BillingStore.state.promoCode)
    const userData = this.props.AuthStore.state.userData && toJS(this.props.AuthStore.state.userData)
    const baseTeamData = this.props.BaseTeamStore.state.data && toJS(this.props.BaseTeamStore.state.data)
    const baseTeamMembers = (baseTeamData.teamMembers && baseTeamData.teamMembers.data) || []
    
    if (userData.paidTeamId && userData.paidTeamId !== userData.teamId && baseTeamMembers.length < 2) {
      this.setState({ alertBannerVisible: true, alertBannerMessage: "extteam" })
    } else if (!promoCode && userData.plan && !userData.plan.isPaid) {
      this.setState({ alertBannerVisible: true, alertBannerMessage: "promo" })
    } 
  }

  componentDidUpdate(prevProps) {
    const plan = this.props.AuthStore.state.userData && toJS(this.props.AuthStore.state.userData.plan)

    if (this.state.alertBannerVisible && plan?.isPaid) {

      this.setState({ alertBannerVisible: false })
    }
  }

  hideAlertBanner = () => this.setState({ alertBannerVisible: false })


  render() {
    return (
      <div className="Layout__wrapper">
        <AlertBanner hideAlertBanner={this.hideAlertBanner} visible={this.state.alertBannerVisible} message={this.state.alertBannerMessage} />
        <div className={`Layout__main${this.state.alertBannerVisible ? " alert-banner-visible" : ""}`}>
          <div className="Layout__Leftsidebar">
            <SideBarMenu alertBannerVisible={this.state.alertBannerVisible} />
          </div>
          <div className="Layout__Right__Sidebar">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default Layout
