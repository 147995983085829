import { observable, action, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'
import { CONFIG } from 'config/config.env'
import { routes, history } from 'config/routes'
import {
  ITokenData,
  IAcceptInvitationApiParams,
  acceptInvitationV2Api,
} from 'services/accept-invitation'
import { errorToast } from 'utils'
import { INVALID_INVITATION_LINK } from 'common/messages'
import {
  getTokenForFriendInviteLinkApi,
  addFriendViaInviteLinkApi,
  teamAddMemberViaInviteLinkApi,
  AddFriendViaInviteLinkApiBody,
  TeamAddMemberViaInviteLinkApiBody,
  groupAddMemberViaInviteLinkApi,
  GroupAddMemberViaInviteLinkApiBody,
} from 'services/invite-link'
import { userGetDataViaUsernameV2Api } from 'services/user'
import { teamGetDataByIdV2Api, getTeamMembersApi } from 'services/teams'
import { groupGetDataByIdV2Api, getGroupMembersApi } from 'services/groups'
import { ENTITY_TYPES } from 'common/constants'

export type IInitialState = {
  data: ITokenData,
  ui: {
    isTokenDecodeLoading: boolean,
    isDownloadForMACLoading: boolean,
    isDownloadForWindowsLoading: boolean,
    isAcceptInvitationLoading: boolean,
  },
}

export const initialState: IInitialState = {
  data: {},
  ui: {
    isTokenDecodeLoading: false,
    isDownloadForMACLoading: false,
    isDownloadForWindowsLoading: false,
    isAcceptInvitationLoading: false,
  },
}

class AcceptInvitationStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  getUserData = async (id) => {
    try {
      const user = await userGetDataViaUsernameV2Api("_", id)
      user.username = `@${user.username}`
      user.name = user.name || user.username || user.email || user.phone

      return user
    } catch(error) {
      console.log(`${new Date().toISOString()} : Error in getUserData:\n`, error)

      throw error
    } 
  } 

  getChannelData = async (id, userId) => {
    try {
      const channel = await groupGetDataByIdV2Api(id)

      channel.id = id    
      channel.createdByName = channel.createdByName.slice(0, channel.createdByName.indexOf(' ') > 0 ? channel.createdByName.indexOf(' ') : channel.createdByName.length)

      try {
        const { groupMembers } = await getGroupMembersApi(channel.id)

        channel.isExistingMember = !!groupMembers.find((member) => member.id === userId)
      } catch (error) {}

      return channel
    } catch(error) {
      console.log(`${new Date().toISOString()} : Error in getChannelData:\n`, error)

      throw error
    } 
  } 

  getTeamData = async (id, userId) => {
    try {
      const team = await teamGetDataByIdV2Api(id)
      team.id = id  
      
      try {
        const { teamMembers } = await getTeamMembersApi(team.id)

        team.isExistingMember = !!teamMembers.find((member) => member.id === userId)
      } catch (error) {}

      return team
    } catch(error) {
      console.log(`${new Date().toISOString()} : Error in getTeamData:\n`, error)

      throw error
    } 
  } 

  parseProfilePageQueryParams = (queryParams: Record<string, string>) => {
    try {
      const {
        isUserInvite,
        isUserInviteLink,
        isTeamInvite,
        isTeamInviteLink,
        isChannelInvite,
        isChannelInviteLink,
        isPrivateGroupInvite,
        isPrivateGroupInviteLink,
      } = queryParams

      const isInviteLink = !!isUserInviteLink || !!isTeamInviteLink || !!isChannelInviteLink || !!isPrivateGroupInviteLink

      let entityType

      if (isUserInvite || isUserInviteLink) {
        entityType = ENTITY_TYPES.user
      } else if (isTeamInvite || isTeamInviteLink) {
        entityType = ENTITY_TYPES.team
      } else if (isChannelInvite || isChannelInviteLink) {
        entityType = ENTITY_TYPES.channel
      } else if (isPrivateGroupInvite || isPrivateGroupInviteLink) {
        entityType = ENTITY_TYPES.privateGroup
      }

      if (!entityType) {
        throw new Error("Invalid invitation")
      }

      return { 
        isInviteLink, 
        entityType,
      }
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in decodeProfilePageQueryParams:\n`, error)

      throw error
    }
  }

  parseProfilePageToken = async (token: string) => {
    try {
      const {
        isExistingUser,
        userId, 
        email, 
        invitingUserId, 
        invitingUsername,
        teamId,
        groupId,
      } = jwtDecode(token)

      let entityId = invitingUserId || teamId || groupId

      if (!entityId && invitingUsername) {
        ({ id: entityId } = await userGetDataViaUsernameV2Api(invitingUsername))
      }

      if (!entityId) {
        throw new Error ("Invalid invitation")
      }

      return {
        entityId,
        isExistingUser,
        userId, 
        email,
      }
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in parseProfilePageToken:\n`, error)

      throw error
    }
  }

  parseProfilePageTokenAndQueryParams = async (token: string, queryParams: Record<string, string>) => {
    try {
      this.setState({ ui: { ...this.state.ui, isTokenDecodeLoading: true } })

      const userData = toJS(this.rootStore.AuthStore.state.userData)
      const localUserId = userData && userData.id

      const { entityType, isInviteLink } = this.parseProfilePageQueryParams(queryParams)
      const { entityId, isExistingUser, userId, email  } = await this.parseProfilePageToken(token)

      const profilePageData = { token }

      if (isInviteLink) {
        profilePageData.isInviteLink = !!isInviteLink
      } else {
        profilePageData.isExistingUser = !!isExistingUser
        profilePageData.userId = userId
        profilePageData.email = email
      }


      switch(entityType) {
        case ENTITY_TYPES.user:
          profilePageData.entity = await this.getUserData(entityId)
          break
        case ENTITY_TYPES.channel:
          profilePageData.entity = await this.getChannelData(entityId, userId || localUserId)
          break
        // Private groups are technically teams under the hood
        case ENTITY_TYPES.team:
        case ENTITY_TYPES.privateGroup:
          profilePageData.entity = await this.getTeamData(entityId, userId || localUserId)          
          break
        default:
      }

      profilePageData.entity.type = entityType

      this.setState({ profilePageData, ui: { ...this.state.data.ui, isTokenDecodeLoading: false } })
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in parseProfilePageTokenAndQueryParams:\n`, error)
      
      errorToast(INVALID_INVITATION_LINK)
      
      this.setState({ ui: { ...this.state.ui, isTokenDecodeLoading: false } })
      
      history.push({ pathname: `${routes.home.path}/` })
    }
  }

  handleAddFriendViaInviteLink = async (
    invitingUserId: string,
    inviteeEmail: string,
    isExistingUser: boolean,
    inviteeUsername?: string,
    inviteeRealName?: string
  ) => {
    try {
      const {
        token: inviteLinkAuthToken,
      } = await getTokenForFriendInviteLinkApi(
        invitingUserId,
        CONFIG.INVITE_LINK_TOKEN_FETCH_SECRET
      )

      const addFriendViaInviteLinkApiBody: AddFriendViaInviteLinkApiBody = {
        email: inviteeEmail,
        isExistingUser,
      }

      if (!isExistingUser) {
        addFriendViaInviteLinkApiBody.username = inviteeUsername
        addFriendViaInviteLinkApiBody.realName = inviteeRealName
      }

      await addFriendViaInviteLinkApi(
        invitingUserId,
        addFriendViaInviteLinkApiBody,
        inviteLinkAuthToken
      )
    } catch (error) {
      console.log('Error in handleAddFriendViaInviteLink.\n', error)

      throw error
    }
  }

  handleAddToTeamViaInviteLink = async (
    teamId: number,
    inviteeEmail: string,
    isExistingUser: boolean,
    inviteeUsername?: string,
    inviteeRealName?: string
  ) => {
    try {
      const teamAddMemberViaInviteLinkApiBody: TeamAddMemberViaInviteLinkApiBody = {
        email: inviteeEmail,
        isExistingUser,
      }

      if (!isExistingUser) {
        teamAddMemberViaInviteLinkApiBody.username = inviteeUsername
        teamAddMemberViaInviteLinkApiBody.realName = inviteeRealName
      }

      await teamAddMemberViaInviteLinkApi(
        teamId,
        teamAddMemberViaInviteLinkApiBody,
        this.state.profilePageData.token
      )
    } catch (error) {
      console.log('Error in handleAddToTeamViaInviteLink.\n', error)

      throw error
    }
  }

  handleAddToGroupViaInviteLink = async (
    groupId: number,
    inviteeEmail: string,
    isExistingUser: boolean,
    inviteeUsername?: string,
    inviteeRealName?: string
  ) => {
    try {
      const groupAddMemberViaInviteLinkApiBody: GroupAddMemberViaInviteLinkApiBody = {
        email: inviteeEmail,
        isExistingUser,
      }

      if (!isExistingUser) {
        groupAddMemberViaInviteLinkApiBody.username = inviteeUsername
        groupAddMemberViaInviteLinkApiBody.realName = inviteeRealName
      }

      await groupAddMemberViaInviteLinkApi(
        groupId,
        groupAddMemberViaInviteLinkApiBody,
        this.state.profilePageData.token
      )
    } catch (error) {
      console.log('Error in handleAddToGroupViaInviteLink.\n', error)

      throw error
    }
  }

  handleInvite = async (isExistingUser, email, username, realName) => {
    try {
      const { profilePageData: { userId, token } } = this.state

      const body = {
        isExistingUser,
        email
      }

      if (username) {
        body.username = username
      }

      if (realName) {
        body.realName = realName
      }

      const response = await acceptInvitationV2Api(userId, body, token)

      if (response && response.token) {
        await this.rootStore.AuthStore.updateSessionIfNecessary(response.token)
      }

      if (!isExistingUser) {
        window.analytics.track("Signed Up", { id: userId, user_id: userId, username, realName, email, invited: true });
        window.analytics.identify(userId)
      }

      this.redirectToThankYouPage(email)
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in handleInviteLink:\n`, error)

      if (error.message.startsWith("User with an id of")) {
        errorToast("Invitation expired")
      } else {
        errorToast(error.message)
      }
    }
  }

  handleInviteLink = async (isExistingUser, email, username, realName) => {
    try {
      const { profilePageData: { entity } } = this.state

      if (entity.type === ENTITY_TYPES.user) {
        await this.handleAddFriendViaInviteLink(entity.id, email, isExistingUser, username, realName)
      } else if (entity.type === ENTITY_TYPES.channel) {
        await this.handleAddToGroupViaInviteLink(entity.id, email, isExistingUser, username, realName)
      } else if (entity.type === ENTITY_TYPES.team || entity.type === ENTITY_TYPES.privateGroup) {
        await this.handleAddToTeamViaInviteLink(entity.id, email, isExistingUser, username, realName)
      }
       
      this.redirectToThankYouPage(email)
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in handleInviteLink:\n`, error)

      if (error.message.startsWith("User with an id of")) {
        errorToast("Invitation expired")
      } else {
        errorToast(error.message)
      }
    }
  }

  handleInviteLinkWithLocalSessionData = async () => {
    try {
      const { userData } = toJS(this.rootStore.AuthStore.state)

      await this.handleInviteLink(true, userData.email)
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in handleInviteLinkWithLocalSessionData:\n`, error)

      throw error
    }
  }

  handleAcceptInvitation = async (params: IAcceptInvitationApiParams) => {
    try {
      this.setState({ ui: { ...this.state.ui, isAcceptInvitationLoading: true } })

      if (this.state.profilePageData.isInviteLink) {
        await this.handleInviteLink(params.isExistingUser, params.email, params.username, params.realName)
      } else {
        await this.handleInvite(params.isExistingUser, params.email, params.username, params.realName)
      }

      this.setState({ ui: { ...this.state.ui, isAcceptInvitationLoading: false } })
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in handleAcceptInvitation:\n`, error)
      
      this.setState({ ui: { ...this.state.ui, isAcceptInvitationLoading: false } })

      if (error.message.startsWith("User with an id of")) {
        errorToast("Invitation expired")
      } else {
        errorToast(error.message)
      }
    }
  }

  redirectToThankYouPage = (email) => {
    try {
      const { profilePageData: { entity } } = toJS(this.state)
      const { token } = toJS(this.rootStore.AuthStore.state)

      history.push({
        pathname: `${routes.thankYou.path}/`,
        state: { entity, token, email },
      })
    } catch (error) {
      console.log(`${new Date().toISOString()} : Error in redirectToThankYouPage:\n`, error)
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default AcceptInvitationStore
