import ApiRequestService from 'services/api.service'
import objectToFormData from 'object-to-formdata'
import { content_types } from 'services/utils'
import ApiRequestServiceV2 from 'services/api.service.v2'
import { CONFIG } from 'config/config.env'
import { auth_header } from 'services/utils'

const api = new ApiRequestService()
const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export type ITokenData = {
  iat: number,
  teamName: string,
  whoInvited: string,
  realName: string,
  email: string,
  username: string,
}

/* accept invitation | start */
export type IAcceptInvitationApiParams = {
  email: string,
  realName?: string,
  username?: string,
  isExistingUser?: boolean
}

export type IAcceptInvitationApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITokenData,
}

export const acceptInvitationApi = (
  params: IAcceptInvitationApiParams
): IAcceptInvitationApiResponse => {
  const data = objectToFormData(params)
  return api.postApi('v1/team/invite', data, {
    ...content_types.multipart,
  })
}
/* accept invitation | end */

export interface AcceptInvitationV2ApiBody {
  email: string;
  username: string;
  realName: string;
}

export interface AcceptInvitationV2ApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string;
    token?: string;
  };
}

export const acceptInvitationV2Api = (
  userId: string,
  body: AcceptInvitationV2ApiBody,
  authToken: string
): AddFriendViaInviteLinkApiResponse => {
  return apiRequestServiceV2.patch(
    `v2/users/${userId}/accept-invitation`,
    body,
    auth_header(authToken)
  )
}
