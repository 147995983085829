import ApiRequestService from 'services/api.service'
import objectToFormData from 'object-to-formdata'
import { content_types, auth_header } from 'services/utils'

const api = new ApiRequestService()

export type ITeam = {
  name: string,
  teamImageUrl: string,
  notifyOnSignup: boolean,
  notifyOnRequestAccess: boolean,
  notifyOnNewUpdate: boolean,
  notifyOnOther: boolean,
}

export type ITeamData = ITeam & {
  id: number,
}

/* get Team details| start */
export type IGetTeamDetailsApiParams = {
  id: number,
}

export type IGetTeamDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamData,
}

export const getTeamDetailsApi = (
  params: IGetTeamDetailsApiParams
): IGetTeamDetailsApiResponse => {
  const { id } = params
  return api.getApi(`v1/team/getTeam/${id}`, {
    ...auth_header(),
  })
}
/* get Team details | end */

/* update Team details| start */
export type IUpdateTeamDetailsApiParams = ITeam & {
  teamId: number,
  key?: string,
  file?: string,
}

export type IUpdateTeamDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamData,
}

export const updateTeamDetailsApi = (
  params: IUpdateTeamDetailsApiParams
): IUpdateTeamDetailsApiResponse => {
  const data = objectToFormData(params)
  return api.putApi('v1/team/update', data, {
    ...content_types.multipart,
    ...auth_header(),
  })
}
/* update Team details | end */
