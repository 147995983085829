import React, { Suspense, Fragment, Component } from 'react'
import { inject, observer } from 'mobx-react'
import Routes from './routes'
import Loader from 'components/Loader/Loader'

@inject(stores => {
  const { AppStore, AuthStore } = stores?.rootStore
  return {
    AppStore,
    AuthStore,
  }
})
@observer
class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSessionCheckComplete: false,
    }
  }
  async componentDidMount() {
    const {
      AuthStore: { updateSessionIfNecessary },
    } = this.props


    window.addEventListener('newContentAvailable', () => { })

    await updateSessionIfNecessary()

    this.setState({ isSessionCheckComplete: true })
  }

  componentWillUnmount() {
    window.removeEventListener('newContentAvailable', () => { })
  }

  render() {
    const {
      AuthStore: {
        state: { userData },
      }
    } = this.props
    let user = {
      user_id: userData?.id,
      email: userData?.email,
      teamName: userData?.teamName,
      role: userData?.role,
      teamId: userData?.teamId,
      hide_default_launcher: false,
    }

    if (userData?.realName) {
      user['name'] = userData?.realName
    }

    if (userData?.username) {
      user['username'] = userData?.username
    }

    return (
      <Fragment>
        <Suspense fallback={<Loader />}>
          {this.state.isSessionCheckComplete ? <Routes /> : <Loader />}
        </Suspense>
      </Fragment>
    )
  }
}

export default App
