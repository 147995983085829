import { routes, history, sideBarMenu } from 'config/routes'
import { getState } from '../services/localStorage'

class SideBarMenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  onMyTeamMenuClick = () => {
    history.push(routes.myteamsearchadd.path)
  }

  onUserProfileClick = () => {
    history.push(routes.userProfile.path)
  }

  onBillingMenuClick = () => {
    history.push(routes.billing.path)
  }

  onOpenWebAppClick = () => {
    const token = getState('token')

    window.open(
      `${routes.openWebApp.path}${token ? `?token=${token}` : ''}`,
      '_blank'
    )
  }

  onIntegrationsClick = () => {
    history.push(routes.integrations.path)
  }

  onSettingsMenuClick = () => {
    history.push(routes.settings.path)
  }

  onSignOutClick = () => {
    this.rootStore.AuthStore.reset()
    this.rootStore.BillingStore.reset()
    this.rootStore.PlansStore.reset()
    this.rootStore.TeamsStore.reset()
    this.rootStore.UserStore.reset()
    this.rootStore.AcceptInvitationStore.reset()
    this.rootStore.DownloadYacStore.reset()
    this.rootStore.SettingsStore.reset()
    window.open("https://web.yac.com/#/shortcut/logout", "_blank");
    history.replace(routes.signin.path)
  }

  onSideBarMenuSelect = id => {
    switch (id) {
      case sideBarMenu.myTeam.id: {
        this.onMyTeamMenuClick()
        break
      }
      case sideBarMenu.userProfile.id: {
        this.onUserProfileClick()
        break
      }
      case sideBarMenu.billing.id: {
        this.onBillingMenuClick()
        break
      }
      case sideBarMenu.settings.id: {
        this.onSettingsMenuClick()
        break
      }
      case sideBarMenu.openWebApp.id: {
        this.onOpenWebAppClick()
        break
      }
      case sideBarMenu.signOut.id: {
        this.onSignOutClick()
        break
      }
      case sideBarMenu.integrations.id: {
        this.onIntegrationsClick()
        break
      }

      default: {
        console.log('no cases matched.')
      }
    }
  }
}

export default SideBarMenuStore
