import { observable, action } from 'mobx'
import {
  ITeamsData,
  ITeamMemberData,
  ICreatePrivateTeamApiParams,
  IGetPrivateTeamMembersApiParams,
  IDeletePrivateTeamApiParams,
  IDeletePrivateTeamMemberApiParams,
  IResendInviteToPrivateTeamMemberApiParams,
  IInviteTeammatesPrivateTeamApiParams,
  IEditPrivateTeamApiParams,
  getPrivateTeamsApi,
  getPrivateTeamMembersApi,
  createPrivateTeamApi,
  deletePrivateTeamApi,
  deletePrivateTeamMemberApi,
  resendInviteToPrivateMemberTeamApi,
  editPrivateTeamApi,
} from 'services/teams'
import { errorToast } from 'utils'
import { DEFAULT_PAGINATION_LIMIT } from 'common/constants'
import { teamInviteMemberV2Api } from 'services/teams'

export type IInitialState = {
  data: {
    teams: ITeamsData,
    teamMembers: {
      [key]: ITeamMemberData,
    },
  },
  ui: {
    isGetPrivateTeamsLoading: boolean,
    isCreatePrivateTeamLoading: boolean,
    isCreatePrivateTeamModalOpen: boolean,
    isEditPrivateTeamLoading: boolean,
    isDeletePrivateTeamLoading: boolean,
    isPrivateTeamInviteTeammatesLoading: boolean,
    isMemberChangeRoleLoading: boolean,
    isMemberRemoveLoading: boolean,
  },
}

export const initialState: IInitialState = {
  data: {
    teams: null,
    teamMembers: {},
  },
  ui: {
    isGetPrivateTeamsLoading: false,
    isCreatePrivateTeamLoading: false,
    isCreatePrivateTeamModalOpen: false,
    isEditPrivateTeamLoading: false,
    isDeletePrivateTeamLoading: false,
    isPrivateTeamInviteTeammatesLoading: false,
    isMemberChangeRoleLoading: false,
    isMemberRemoveLoading: false,
  },
}

class TeamsStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  onGetPrivateTeams = async () => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isGetPrivateTeamsLoading: true } })
    try {
      const response = await getPrivateTeamsApi()
      const { ui, data } = this.state
      if (response?.status) {
        this.setState({
          ui,
          data: {
            ...data,
            teams: response?.data?.teams || null,
          },
        })
      } else {
        errorToast(response?.message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isGetPrivateTeamsLoading: false } })
  }

  onGetPrivateTeamMembers = async (params: IGetPrivateTeamMembersApiParams) => {
    try {
      const response = await getPrivateTeamMembersApi(params)
      if (response?.status) {
        const {
          data,
          data: { teamMembers },
        } = this.state
        const { teamId } = params
        const newMembers = {
          data: response?.data?.teamUsers || null,
          totalMembers: response?.data?.totalMembers || 0,
          pageNumber: response?.data?.pageNumber || 0,
          paginationLimit:
            Math.ceil(response?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
              1 || 0,
        }
        const newTeamMembers = {
          ...teamMembers,
          [teamId]: newMembers,
        }
        this.setState({
          data: {
            ...data,
            teamMembers: newTeamMembers,
          },
        })
      } else {
        console.log(response?.message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
  }

  onHandleCreatePrivateTeamModal = (value: boolean) => {
    const { ui } = this.state
    this.setState({
      ui: { ...ui, isCreatePrivateTeamModalOpen: value },
    })
  }

  @action.bound
  onCreatePrivateTeam = async (params: ICreatePrivateTeamApiParams) => {
    const { ui } = this.state
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    this.setState({ ui: { ...ui, isCreatePrivateTeamLoading: true } })
    try {
      const response = await createPrivateTeamApi(params)

      const { message, status } = response
      const { ui, data } = this.state
      if (status && response?.data?.teamId) {
        const newTeam = {
          id: response.data.teamId,
          name: response?.data?.teamName || params?.name,
          whoCreated: response?.data?.whoCreated || userData?.realName,
          creatorId: response?.data?.creatorId || userData?.id,
          isCreator: response?.data?.isCreator || 1,
        }
        this.setState({
          ui: {
            ...ui,
            isCreatePrivateTeamLoading: false,
            isCreatePrivateTeamModalOpen: false,
          },
          data: {
            ...data,
            teams: [newTeam, ...data.teams],
          },
        })
        const updateUser = {
          ...userData,
          teamSize: response?.data?.teamSize || userData?.teamSize || 0,
        }
        AuthStore.setUserData(updateUser)
      } else {
        errorToast(message)
        this.setState({ ui: { ...ui, isCreatePrivateTeamLoading: false } })
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isCreatePrivateTeamLoading: false } })
    }
  }

  onEditPrivateTeam = async (params: IEditPrivateTeamApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isEditPrivateTeamLoading: true } })
    try {
      const response = await editPrivateTeamApi({ ...params, isPrivate: 1 })
      const { message, status } = response
      if (status) {
        const { teamId } = params
        const { data } = this.state
        const updateTeam = {
          name: response?.data?.name || params?.name,
        }
        this.setState({
          ui: {
            ...ui,
            isEditPrivateTeamLoading: false,
          },
          data: {
            ...data,
            teams: data.teams.map(team =>
              team.id === teamId ? { ...team, ...updateTeam } : team
            ),
          },
        })
        return Promise.resolve(true)
      } else {
        errorToast(message)
        this.setState({ ui: { ...ui, isEditPrivateTeamLoading: false } })
        return Promise.resolve(false)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isEditPrivateTeamLoading: false } })
      return Promise.reject(false)
    }
  }

  onDeletePrivateTeam = async (params: IDeletePrivateTeamApiParams) => {
    const { ui } = this.state
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    this.setState({ ui: { ...ui, isDeletePrivateTeamLoading: true } })
    try {
      const response = await deletePrivateTeamApi(params)
      const { ui, data } = this.state
      const { teamId } = params
      if (response?.status) {
        this.setState({
          ui: { ...ui, isDeletePrivateTeamLoading: false },
          data: {
            ...data,
            teams: data.teams.filter(({ id }) => id !== teamId),
          },
        })
        const updateUserData = {
          ...userData,
          teamSize: response?.data?.teamSize || 0,
        }
        AuthStore.setUserData(updateUserData)
      } else {
        errorToast(response?.message)
        this.setState({ ui: { ...ui, isDeletePrivateTeamLoading: false } })
      }
    } catch (error) {
      this.setState({ ui: { ...ui, isDeletePrivateTeamLoading: false } })
      const { message } = error
      errorToast(message)
    }
  }

  onNewInviteeViaEmail = async (
    params: IInviteTeammatesPrivateTeamApiParams
  ) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isPrivateTeamInviteTeammatesLoading: true } })
    try {
      await teamInviteMemberV2Api(params.teamId, {
        emails: params.invitees,
        resendInvite: true,
      })
      const response = await getPrivateTeamMembersApi({
        teamId: params.teamId,
        pageNumber: 0,
        creatorId: 0,
      })
      if (response?.status) {
        const {
          data,
          data: { teamMembers },
        } = this.state
        const { teamId } = params
        const newMembers = {
          data: response?.data?.teamUsers || null,
          totalMembers: response?.data?.totalMembers || 0,
          pageNumber: response?.data?.pageNumber || 0,
          paginationLimit:
            Math.ceil(response?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
              1 || 0,
        }
        const newTeamMembers = {
          ...teamMembers,
          [teamId]: newMembers,
        }
        this.setState({
          ui: { ...ui, isPrivateTeamInviteTeammatesLoading: false },
          data: {
            ...data,
            teamMembers: newTeamMembers,
          },
        })
        return Promise.resolve(true)
      } else {
        errorToast(response?.message)
        this.setState({
          ui: { ...ui, isPrivateTeamInviteTeammatesLoading: false },
        })
        return Promise.resolve(false)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isPrivateTeamInviteTeammatesLoading: false },
      })
      return Promise.resolve(false)
    }
  }

  onRemoveMember = async (params: IDeletePrivateTeamMemberApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isMemberRemoveLoading: true } })
    try {
      const response = await deletePrivateTeamMemberApi(params)
      if (response?.status) {
        const {
          data,
          data: { teamMembers },
        } = this.state
        const { teamId } = params
        const newMembers = {
          data: response?.data?.teamUsers || null,
          totalMembers: response?.data?.totalMembers || 0,
          pageNumber: response?.data?.pageNumber || 0,
          paginationLimit:
            Math.ceil(response?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
              1 || 0,
        }
        const newTeamMembers = {
          ...teamMembers,
          [teamId]: newMembers,
        }
        this.setState({
          ui: { ...ui, isMemberRemoveLoading: false },
          data: {
            ...data,
            teamMembers: newTeamMembers,
          },
        })
        return Promise.resolve(true)
      } else {
        errorToast(response?.message)
        this.setState({
          ui: { ...ui, isMemberRemoveLoading: false },
        })
      }
      return Promise.resolve(false)
    } catch (error) {
      this.setState({
        ui: { ...ui, isMemberRemoveLoading: false },
      })
      const { message } = error
      errorToast(message)
      return Promise.reject(false)
    }
  }

  onChangeRoleOfMember = () => {
    console.log('onChangeRoleOfMember')
  }

  onResendInviteToMember = async (
    params: IResendInviteToPrivateTeamMemberApiParams
  ) => {
    try {
      const { status, message } = await resendInviteToPrivateMemberTeamApi(
        params
      )

      if (!status) {
        errorToast(message)
      }
      return Promise.resolve(true)
    } catch (error) {
      const { message } = error
      errorToast(message)
      return Promise.reject(false)
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default TeamsStore
