import { observable } from 'mobx'
import remotedev from 'mobx-remotedev'

import AppStore from './AppStore'
import AuthStore from './AuthStore'
import AcceptInvitationStore from './AcceptInvitationStore'
import SideBarMenuStore from './SideBarMenuStore'
import DownloadYacStore from './DownloadYacStore'
import SettingsStore from './SettingsStore'
import PlansStore from './PlansStore'
import BillingStore from './BillingStore'
import UserStore from './UserStore'
import TeamsStore from './TeamsStore'
import BaseTeamStore from './BaseTeamStore'
import FriendsStore from './FriendsStore'

class RootStore {
  @observable version = 1
  constructor() {
    this.AppStore = new AppStore(this)
    this.AuthStore = new AuthStore(this)
    this.AcceptInvitationStore = new AcceptInvitationStore(this)
    this.SideBarMenuStore = new SideBarMenuStore(this)
    this.DownloadYacStore = new DownloadYacStore(this)
    this.SettingsStore = new SettingsStore(this)
    this.PlansStore = new PlansStore(this)
    this.BillingStore = new BillingStore(this)
    this.UserStore = new UserStore(this)
    this.TeamsStore = new TeamsStore(this)
    this.BaseTeamStore = new BaseTeamStore(this)
    this.FriendsStore = new FriendsStore(this)
  }
}

const rootStore = remotedev(RootStore, { global: true })
export default new rootStore()
