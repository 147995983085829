import { observable, action } from 'mobx'
import {
  IUserData,
  getUserProfileApi,
  updateUserProfileApi,
  IUpdateUserProfileApiParams,
  unsubscribeEmail,
  updateUserEmailApi,
} from 'services/user'
import { errorToast } from 'utils'
import {
  USER_UNSUBSCRIBED_SUCCESS,
  USER_UNSUBSCRIBED_ERROR,
} from 'common/messages'
import { routes, history } from 'config/routes'

export type IInitialState = {
  data: IUserData,
  ui: {
    isUserProfileLoading: boolean,
    isUserProfileFormSubmitting: boolean,
  },
}

export const initialState: IInitialState = {
  data: {},
  ui: {
    isUserProfileLoading: false,
    isUserProfileFormSubmitting: false,
  },
}

class UserStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  onGetUserProfile = async () => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isUserProfileLoading: true } })
    try {
      const response = await getUserProfileApi()
      const { message, status, data } = response
      if (status) {
        const { username, realName, email, mobileImagePath } = data
        const userData = {
          username: username ? username : '',
          realName: realName ? realName : '',
          email,
          image: mobileImagePath,
        }
        this.setState({ data: userData })
      } else {
        errorToast(message)
      }
      this.setState({
        ui: { ...ui, isUserProfileLoading: false },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isUserProfileLoading: false } })
    }
  }

  onUpdateUserProfile = async (params: IUpdateUserProfileApiParams) => {
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    const { ui } = this.state
    this.setState({ ui: { ...ui, isUserProfileFormSubmitting: true } })
    try {
      const { email: unconfirmedEmail, ...restOfParams } = params

      const response = await updateUserProfileApi(restOfParams)

      const { message, status, data } = response

      if (status) {
        this.setState({ data })
        const { realName, username, email, mobileImagePath } = data

        const updateUser = {
          ...userData,
          realName,
          username,
          email,
          image: mobileImagePath,
        }

        if (unconfirmedEmail && userData.email !== unconfirmedEmail) {
          await updateUserEmailApi(userData.id, unconfirmedEmail)

          updateUser.unconfirmedEmail = unconfirmedEmail

          history.push({
            pathname: routes.emailconfirmation.path,
            state: {
              email: unconfirmedEmail,
              type: 'emailUpdate',
            },
          })
        }

        await AuthStore.setUserData(updateUser)
      } else {
        errorToast(message)
      }

      this.setState({
        ui: { ...ui, isUserProfileFormSubmitting: false },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isUserProfileFormSubmitting: false } })
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }

  @action
  unsubscribeUserEmail = async params => {
    this.isUnsubscribingEmail = true
    let response = await unsubscribeEmail(params)
    console.log('Respoisnsese', response)
    try {
      if (response.data) {
        console.log(USER_UNSUBSCRIBED_SUCCESS)
      } else {
        console.log(USER_UNSUBSCRIBED_ERROR)
      }
    } catch (e) {
      console.log('Error : ', e)
    }
  }
}

export default UserStore
