import { observable, action } from 'mobx'
import { routes, history } from 'config/routes'
import {
  getBillingHistoryApi,
  getBillingDetailsApi,
  saveBillingDetailsApi,
  ISaveBillingDetailsApiParams,
  IGetPlanDetailsApiParams,
  upgradePlan,
  getBillingPlansList,
  upgradeBillingInfo,
  validatePromoCode,
  getPlanDetailsApi,
  downgradeAccount,
  getBillingPlanDetailsApi,
} from 'services/billing'
import { getSubscribedPlanDetailsApi } from 'services/plans'
import { errorToast } from 'utils'
import {
  PLAN_FREE,
  PLAN_MONTHLY,
  PLAN_ANNUALLY,
  CARD_BRANDS as cardBrandToPfClass,
} from 'common/constants'
import {
  INVALID_PROMO_CODE,
  UPGRADE_PLAN_ERROR,
  ACCOUNT_DOWNGRADE_ERROR,
} from 'common/messages'

export type IInitialState = {
  billingDetails: object,
  billingHistory: [],
  promoCode: string,
  ui: {
    isBillingLoading: boolean,
    isBillingHistoryLoading: boolean,
    isBillingFormSubmitting: boolean,
    isCompanyInformationEditable: boolean,
    isPaymentMethodEditable: boolean,
  },
}

export const initialState: IInitialState = {
  billingDetails: {},
  billingHistory: [],
  promoCode: "",
  ui: {
    isBillingLoading: false,
    isBillingHistoryLoading: false,
    isBillingFormSubmitting: false,
    isCompanyInformationEditable: false,
    isPaymentMethodEditable: false,
  },
}

class BillingStore {
  @observable state: IInitialState
  @observable isBillingEditMode = false
  @observable isBillingLoading = false
  @observable savedCardDetails = {}
  @observable isUpgradingPlan = false
  @observable billingPlanList = []
  @observable billingPlanResponse = {}
  @observable isBillingPlansLoading = false
  @observable currentPlan = {}
  @observable currentBillingPlan = {}
  @observable isUpgradingPaymentMethod = false
  @observable promoCodeVal = ''
  @observable isPromoCodeApplied = false
  @observable name = ''
  @observable isValidatingPromoCode = ''
  @observable afterPromoCodeApply = ''
  @observable paymentDetails = {}
  @observable disableLeftMenuBtns = false
  @observable isPaymentModalOpen = false
  @observable paymentModalErrMsgs = ''
  @observable isUpdatePlanModalOpen = false
  @observable isDeactivateAccModalOpen = false
  @observable upgradePlanFormErrMsgs = ''
  @observable isCongratsModalOpen = false
  @observable showRibbonError = true
  @observable showBSRibbonError = true
  @observable cardClass = ''
  @observable isPlanLoading = false
  @observable isDowngradeAccountLoading = false

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  @action.bound
  toggleEditMode = val => {
    this.isBillingEditMode = val
  }

  @action.bound
  onNameChange = val => {
    this.name = val
  }

  @action.bound
  getBillingDetails = async () => {
    this.isBillingLoading = true
    try {
      const response = await getBillingDetailsApi()
      if (response.status && response.data) {
        this.savedCardDetails = response.data.cardDetails
        this.paymentDetails = response.data.paymentDetails
        this.name = this.savedCardDetails.name
        this.setBrandIcon(this.savedCardDetails.brand)
      } else {
        console.log('Error', response)
      }
      this.isBillingLoading = false
    } catch (e) {
      this.isBillingLoading = false
      errorToast('Error in getting billing details: ', e)
    }
  }

  @action.bound
  fetchBillingPlansList = async () => {
    const { AuthStore, planDays } = this.rootStore
    const {
      state: { userData },
    } = AuthStore

    this.isBillingPlansLoading = true
    try {
      const response = await getBillingPlansList()
      if (response.status && response.data) {
        this.billingPlanResponse = response.data
        this.billingPlanList = response.data.plans.reverse()
        if (response?.data?.teamSize) {
          const updateUser = {
            ...userData,
            teamSize: response.data.teamSize,
          }
          AuthStore.setUserData(updateUser)
        }
        if (planDays <= 0) {
          this.toggleRibbonError(true)
        }
        this.setPlansForPages()
        this.isBillingPlansLoading = false
      } else {
        this.isBillingPlansLoading = false
        console.log('Error', response)
      }
    } catch (e) {
      errorToast('Error in fetching plans list: ', e)
      this.isBillingPlansLoading = false
    }
  }

  @action.bound
  setPlansForPages = () => {
    const {
      state: { userData },
    } = this.rootStore.AuthStore
    this.currentPlan = this.billingPlanList.find(
      plan => plan.nickname === userData?.plan?.nickname
    )
    this.currentBillingPlan = this.billingPlanList.find(
      plan =>
        plan.nickname !== userData?.plan?.nickname &&
        plan.nickname !== PLAN_FREE
    )
  }

  @action.bound
  handlePromoCodeChange = e => {
    this.promoCodeVal = e.target.value
  }

  @action.bound
  validatePromoCode = async code => {
    this.isValidatingPromoCode = true
    try {
      const response = await validatePromoCode(code, this.currentBillingPlan.id)
      if (response.data) {
        this.afterPromoCodeApply = response.data
        this.isPromoCodeApplied = true
      } else {
        errorToast(INVALID_PROMO_CODE)
        this.isPromoCodeApplied = false
        this.promoCodeVal = ''
      }
      this.isValidatingPromoCode = false
    } catch (e) {
      this.isPromoCodeApplied = false
      this.isValidatingPromoCode = false
      errorToast('Error validating promo code: ', e)
    }
  }

  @action.bound
  removePromoCode = () => {
    this.isPromoCodeApplied = false
    this.afterPromoCodeApply = {}
    this.promoCodeVal = ''
  }

  // onGetBillingDetails = async () => {
  //   const { ui } = this.state
  //   this.setState({ ui: { ...ui, isBillingLoading: true } })
  //   try {
  //     const response = await getBillingDetailsApi()
  //     const { message, status, data } = response
  //     if (status && data) {
  //       const { companyDetails } = data
  //       const { state, country } = companyDetails
  //       const selectState = states.find(c => c.value === state)
  //       const selectCountry = countries.find(c => c.value === country)
  //       this.setState({
  //         billingDetails: {
  //           ...data,
  //           companyDetails: {
  //             ...companyDetails,
  //             state: selectState ? selectState : state,
  //             country: selectCountry ? selectCountry : country,
  //           },
  //         },
  //       })
  //     } else {
  //       errorToast(message)
  //     }
  //   } catch (error) {
  //     const { message } = error
  //     errorToast(message)
  //   }
  //   this.setState({ ui: { ...ui, isBillingLoading: false } })
  // }

  @action.bound
  onPlanUpgrade = async params => {
    this.isUpgradingPlan = true
    this.disableLeftMenuBtns = true
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    try {
      const response = await this.upgradePlan(params)
      if (response.data) {
        let { data } = await getSubscribedPlanDetailsApi()
        this.togglePlanUpdateModal(false)
        this.toggleCongratsModal(true)
        // this.setPlanToLS(subscribedPlanDetails.data)
        const updateUser = {
          ...userData,
          plan: { ...userData.plan, ...data },
        }
        await AuthStore.setUserData(updateUser)
      } else if (!response.status) {
        errorToast(response.message)
      } else {
        errorToast(UPGRADE_PLAN_ERROR)
      }
      this.togglePlanUpdateModal(false)
      this.isUpgradingPlan = false
      this.afterPromoCodeApply = {}
      this.isPromoCodeApplied = false
      this.disableLeftMenuBtns = false
    } catch (e) {
      errorToast('Error in upgrading plan: ', e)
      this.isUpgradingPlan = false
      this.disableLeftMenuBtns = false
      this.togglePlanUpdateModal(false)
      this.afterPromoCodeApply = {}
      this.isPromoCodeApplied = false
    }
  }

  @action.bound
  onPaymentMethodUpgrade = async params => {
    this.isUpgradingPaymentMethod = true
    this.disableLeftMenuBtns = true
    try {
      const response = await this.upgradePlan(params)
      if (response.data) {
        this.toggleEditMode(false)
        this.getBillingDetails()
      } else {
        errorToast(`Error: ${response.message}`)
      }
      this.togglePaymentModal(false)
      this.isUpgradingPaymentMethod = false
      this.disableLeftMenuBtns = false
    } catch (e) {
      errorToast('Error in upgrading plan: ', e)
      this.togglePaymentModal(false)
      this.isUpgradingPlan = false
      this.disableLeftMenuBtns = false
    }
  }

  @action.bound
  setBrandIcon = brand => {
    this.cardClass = cardBrandToPfClass[brand.toLowerCase()]
    // let babrand = brand.toLowerCase()
    // console.log('babrandbabrand', brand, babrand, cardBrandToPfClass)
    // if (cardBrandToPfClass[babrand]) {
    //   this.cardClass = cardBrandToPfClass[brand]
    // }
    // if (babrand in cardBrandToPfClass) {
    //   pfClass = cardBrandToPfClass[brand]
    // }
    // console.log('pfClass', pfClass)
    // for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
    //   brandIconElement.classList.remove(brandIconElement.classList[i])
    // }
    // brandIconElement.classList.add('pf')
    // brandIconElement.classList.add(this.cardClass)
  }

  @action.bound
  upgradePlan = async params => {
    return await upgradePlan(params)
  }

  setPlanToLS = newPlan => {
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    const user = {
      ...userData,
      plan: { ...userData.plan, ...newPlan },
    }
    AuthStore.setUserData(user)
  }

  @action.bound
  onBillingInfoUpdate = async params => {
    this.isUpgradingPaymentMethod = true
    const response = await upgradeBillingInfo(params)
    try {
      if (response.data) {
        this.isUpgradingPaymentMethod = false
      } else {
        console.log('Error occured', response)
        this.isUpgradingPaymentMethod = false
      }
    } catch (e) {
      errorToast('Eror in updating: ', e)
    }
  }

  @action.bound
  toggleToMonthAndYearlyPlan = () => {
    if (this.currentPlan.nickname === PLAN_ANNUALLY) {
      this.currentPlan = this.getPlanFromList(PLAN_MONTHLY)
    } else if (this.currentPlan.nickname === PLAN_MONTHLY) {
      this.currentPlan = this.getPlanFromList(PLAN_ANNUALLY)
    }
  }

  @action.bound
  toggleToUpgradeMonthAndYearlyPlan = () => {
    if (this.currentBillingPlan.nickname === PLAN_ANNUALLY) {
      this.currentBillingPlan = this.getPlanFromList(PLAN_MONTHLY)
    } else if (this.currentBillingPlan.nickname === PLAN_MONTHLY) {
      this.currentBillingPlan = this.getPlanFromList(PLAN_ANNUALLY)
    }
  }

  getPlanFromList = planName => {
    if (this.billingPlanList?.length) {
      return this.billingPlanList.find(plan => plan.nickname === planName)
    }
  }

  togglePaymentModal = value => {
    this.isPaymentModalOpen = value
  }

  togglePlanUpdateModal = value => {
    this.isUpdatePlanModalOpen = value
  }

  toggleDeactivateAccModal = value => {
    this.isDeactivateAccModalOpen = value
  }

  closeChangePlanModal = () => {
    // this.isPromoCodeApplied = false
    // this.afterPromoCodeApply = ''
    this.togglePlanUpdateModal(false)
  }

  toggleCongratsModal = val => {
    this.isCongratsModalOpen = val
  }

  toggleRibbonError = val => {
    this.showRibbonError = val
  }

  toggleBSRibbonError = val => {
    this.showBSRibbonError = val
  }

  onSaveBillingDetails = async (
    params: ISaveBillingDetailsApiParams,
    stripe
  ) => {
    const { ui } = this.state
    const { isPaymentMethodEditable } = ui
    const { fullNameOnCard, ...rest } = params
    this.setState({ ui: { ...ui, isBillingFormSubmitting: true } })
    if (!isPaymentMethodEditable) {
      this.onUpdateBillingDetails(rest)
    } else {
      if (stripe) {
        try {
          const payload = await stripe.createToken({ name: fullNameOnCard })
          if (payload?.token?.id) {
            this.onUpdateBillingDetails({
              paymentToken: payload.token.id,
              ...rest,
            })
          } else if (payload?.error) {
            const {
              error: { message },
            } = payload
            console.log('payload?.error', payload)
            errorToast(message)
            this.setState({ ui: { ...ui, isBillingFormSubmitting: false } })
          }
        } catch (error) {
          this.setState({ ui: { ...ui, isBillingFormSubmitting: false } })
        }
      } else {
        console.log("Stripe.js hasn't loaded yet.")
      }
    }
  }

  onUpdateBillingDetails = async (params: ISaveBillingDetailsApiParams) => {
    const { ui } = this.state
    try {
      const response = await saveBillingDetailsApi(params)
      const { message, status } = response
      if (!status) {
        errorToast(message)
      }
      this.setState({
        ui: { ...ui, isBillingFormSubmitting: false },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isBillingFormSubmitting: false },
      })
    }
  }

  onGetBillingHistory = async () => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isBillingHistoryLoading: true } })
    try {
      const response = await getBillingHistoryApi()
      const { message, status, data } = response
      if (status && data) {
        this.setState({ billingHistory: data })
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isBillingHistoryLoading: false } })
  }

  onHandleCompanyInformationEditable = () => {
    const { ui } = this.state
    const { isCompanyInformationEditable } = ui
    this.setState({
      ui: {
        ...ui,
        isCompanyInformationEditable: !isCompanyInformationEditable,
      },
    })
  }

  onHandlePaymentMethodEditable = () => {
    const { ui } = this.state
    const { isPaymentMethodEditable } = ui
    this.setState({
      ui: {
        ...ui,
        isPaymentMethodEditable: !isPaymentMethodEditable,
      },
    })
  }

  onUpgradePlan = () => {
    history.push({
      pathname: routes.plans.path,
    })
  }

  onChangePlan = () => {
    history.push({
      pathname: routes.billing.path,
    })
    // upgradeplan
  }

  onAddSeats = () => {
    const { AuthStore } = this.rootStore
    const {
      state: {
        userData: { plan },
      },
    } = AuthStore

    if (plan && Object.keys(plan).length) {
      if (plan?.nickname === PLAN_FREE || !plan?.cardAvailable) {
        this.onChangePlan()
      } else {
        history.push({
          pathname: routes.billing.path,
        })
      }
    }
  }

  onGetPlanDetails = async (params: IGetPlanDetailsApiParams) => {
    this.isPlanLoading = true
    try {
      const response = await getPlanDetailsApi(params)
      const { message, status, data } = response
      if (status && data?.plan && Object.keys(data?.plan).length) {
        this.setPlanToLS(data.plan)
        this.isPlanLoading = false
      } else {
        console.log({ message })
        this.isPlanLoading = false
      }
    } catch (error) {
      const { message } = error
      console.log({ message })
      this.isPlanLoading = false
    }
  }

  onGetBillingPlanDetails = async () => {
    try {
      const response = await getBillingPlanDetailsApi()
      const { message, status, data } = response
      const { AuthStore } = this.rootStore
      const {
        state: { userData },
      } = AuthStore

      if (status && data?.plan) {
        const planName = data.plan && data.plan.metadata && data.plan.metadata.NAME
        const isPaid = planName === "FREELANCER" || planName === "STARTUP";

        const user = {
          ...userData,
          teamSize: data?.teamSize,
          memberSize: data?.memberSize,
          teamEmail: data?.teamEmail,
          emailHash: data?.emailHash,
          allowedFeatures: data?.allowedFeatures,
          plan: { ...userData.plan, ...data.plan, isPaid },
        }

        AuthStore.setUserData(user)
      } else {
        console.log({ message })
      }
    } catch (error) {
      const { message } = error
      console.log({ message })
    }
  }

  @action.bound
  onDowngradeAccount = async () => {
    this.isDowngradeAccountLoading = true
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    try {
      const response = await downgradeAccount()
      const { message, status, data } = response
      if (status) {
        const { plan } = data
        const updateUser = {
          ...userData,
          plan: plan,
        }
        AuthStore.setUserData(updateUser)
        this.isDowngradeAccountLoading = false
        this.toggleDeactivateAccModal(false)
        history.push({
          pathname: routes.myteamsearchadd.path,
        })
      } else {
        errorToast(message ? message : ACCOUNT_DOWNGRADE_ERROR)
        this.isDowngradeAccountLoading = false
      }
    } catch (error) {
      const { message } = error
      errorToast(message ? message : ACCOUNT_DOWNGRADE_ERROR)
      this.isDowngradeAccountLoading = false
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
    this.isBillingEditMode = false
    this.isBillingLoading = false
    this.savedCardDetails = {}
    this.isUpgradingPlan = false
    this.billingPlanList = []
    this.billingPlanResponse = {}
    this.isBillingPlansLoading = false
    this.currentPlan = {}
    this.currentBillingPlan = {}
    this.isUpgradingPaymentMethod = false
    this.promoCodeVal = ''
    this.isPromoCodeApplied = false
    this.name = ''
    this.isValidatingPromoCode = ''
    this.afterPromoCodeApply = ''
    this.paymentDetails = {}
    this.disableLeftMenuBtns = false
    this.isPaymentModalOpen = false
    this.paymentModalErrMsgs = ''
    this.isUpdatePlanModalOpen = false
    this.isDeactivateAccModalOpen = false
    this.upgradePlanFormErrMsgs = ''
    this.isCongratsModalOpen = false
    this.showRibbonError = true
    this.showBSRibbonError = true
    this.cardClass = ''
    this.isPlanLoading = false
    this.isDowngradeAccountLoading = false
  }
}

export default BillingStore
