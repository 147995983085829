import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { sideBarMenu } from 'config/routes'
import { HeaderMobileView } from 'components/HeaderMobileView/HeaderMobileView'
import { NavigationLeftsideBar } from 'components/NavigationLeftsideBar/NavigationLeftsideBar'
import { PLAN_FREE, PLAN_PRO } from 'common/constants'

@inject(stores => {
  const { AuthStore, SideBarMenuStore, BillingStore } = stores?.rootStore
  return {
    AuthStore,
    SideBarMenuStore,
    BillingStore,
  }
})
@withRouter
@observer
class SideBarMenu extends Component {
  state = {
    isToggleMenu: false,
  }

  onMenuToggle = () => {
    const { isToggleMenu } = this.state
    this.setState({ isToggleMenu: !isToggleMenu })
  }

  planTrialDays = () => {
    const {
      props: {
        AuthStore: { planDays },
      },
    } = this

    return planDays > 0 ? `Expires in ${planDays} days` : 'Trial Ended'
  }

  userPlanStatus = plan => {
    const planStatus = this.planTrialDays()

    if (plan && Object.keys(plan).length) {
      if (plan?.nickname === PLAN_FREE && plan?.inTrialDays) {
        return `${planStatus}`
      } else if (
        plan?.nickname !== PLAN_FREE &&
        plan?.inTrialDays &&
        !plan?.cardAvailable
      ) {
        return `${planStatus}`
      } else if (
        plan?.nickname !== PLAN_FREE &&
        !plan?.inTrialDays &&
        plan?.cardAvailable
      ) {
        return PLAN_PRO
      }
    }
  }

  render() {
    const {
      props: {
        SideBarMenuStore,
        AuthStore: {
          state: { userData },
        },
        BillingStore,
        match: { path },
      },
      state: { isToggleMenu },
      onMenuToggle,
    } = this
    const { onSideBarMenuSelect } = SideBarMenuStore
    const { onChangePlan, onAddSeats } = BillingStore
    const { teamName, teamSize, teamImageUrl, role, plan } = userData
    // const planStatus = this.userPlanStatus(plan)
    // const isHideUpgradeButton =
    //   planStatus === PLAN_PRO ||
    //   (plan?.nickname !== PLAN_FREE && plan?.cardAvailable)
    //     ? true
    //     : false

    return (
      <Fragment>
        <HeaderMobileView
          teamName={teamName}
          teamSize={teamSize}
          teamLogo={teamImageUrl}
          currentPlan={plan}
          userRole={role}
          onMenuToggle={onMenuToggle}
          onUpgradePlan={onChangePlan}
          onAddSeats={onAddSeats}
          planStatus={null}
          isHideUpgradeButton={true}
        />
        <NavigationLeftsideBar
          teamName={teamName}
          teamSize={teamSize}
          teamLogo={teamImageUrl}
          currentPlan={plan}
          userRole={role}
          sideBarMenu={sideBarMenu}
          onSideBarMenuSelect={onSideBarMenuSelect}
          activePath={path}
          isToggleMenu={isToggleMenu}
          onUpgradePlan={onChangePlan}
          onAddSeats={onAddSeats}
          disableAllBtns={BillingStore.disableLeftMenuBtns}
          planStatus={null}
          isHideUpgradeButton={true}
          alertBannerVisible={this.props.alertBannerVisible}
        />
      </Fragment>
    )
  }
}

export default SideBarMenu
