import ApiRequestService from 'services/api.service'
import ApiRequestServiceV2 from 'services/api.service.v2'
import { auth_header } from 'services/utils'
import { CONFIG } from 'config/config.env'

const api = new ApiRequestService()
const apiRequestServiceV2 = new ApiRequestServiceV2(CONFIG.PUBLIC_URL)

export type ITeamsData = {
  id: number,
  name: string,
  whoCreated: string,
  creatorId: number,
  isCreator: number,
}

export type ITeamMember = {
  id: string,
  realName: string,
  email: string,
  role: string,
  isActive: number,
}

export type ITeamMemberData = {
  teamUsers: ITeamMember[],
  totalMembers: number,
  pageNumber: number,
}

/* get privte teams | start */
export type IGetPrivateTeamsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamsData,
}

export const getPrivateTeamsApi = (): IGetPrivateTeamsApiResponse => {
  return api.getApi(`v2/private-teams`, {
    ...auth_header(),
  })
}
/* get privte teams  | end */

/* get privte team members | start */
export type IGetPrivateTeamMembersApiParams = {
  teamId: Number,
  pageNumber: Number,
}

export type IGetPrivateTeamMembersApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamMemberData,
}

export const getPrivateTeamMembersApi = (
  params: IGetPrivateTeamMembersApiParams
): IGetPrivateTeamMembersApiResponse => {
  const { teamId, pageNumber, creatorId } = params
  return api.getApi(
    `v2/private-teams/members/${teamId}/${pageNumber}/${creatorId}`,
    {
      ...auth_header(),
    }
  )
}
/* get privte team members  | end */

/* Create New Team | start */
export type ICreatePrivateTeamApiParams = {
  name: string,
  invitees: string[],
}

export type ICreatePrivateTeamApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamsData,
}

export const createPrivateTeamApi = async (
  params: ICreatePrivateTeamApiParams
): ICreatePrivateTeamApiResponse => {
  // Due to changing functionality, we need to not invite anyone in the creation call,
  //then invitee them in a subsequent call to a different endpoint
  const createPrivateTeamBody = {
    name: params.name,
    invitees: '',
  }

  const createTeamResponse = await api.postApi(
    'v2/private-teams/createPrivateTeam',
    createPrivateTeamBody,
    auth_header()
  )

  if (createTeamResponse.status && params.invitees.length) {
    await teamInviteMemberV2Api(createTeamResponse.data.teamId, {
      emails: params.invitees,
    })
  }

  return createTeamResponse
}
/* Create New Team | end */

/* Edit new team | start */
export type IEditPrivateTeamApiParams = {
  teamId: number,
  name: string,
  isPrivate: number,
}

export type IEditPrivateTeamApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamsData,
}

export const editPrivateTeamApi = (
  params: IEditPrivateTeamApiParams
): IEditPrivateTeamApiResponse => {
  return api.putApi('v1/team/update', params, {
    ...auth_header(),
  })
}
/* Edit new team | end */

/* Delete new team | start */
export type IDeletePrivateTeamApiParams = {
  teamId: number,
}

export type IDeletePrivateTeamApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const deletePrivateTeamApi = (
  params: IDeletePrivateTeamApiParams
): IDeletePrivateTeamApiResponse => {
  return api.postApi('v2/private-teams/DeletePrivate', params, {
    ...auth_header(),
  })
}
/* Delete new team | end */

/* Invite Teammates for new team | start */
export type IInviteTeammatesPrivateTeamApiParams = {
  invitees: string[],
  teamId: number,
  pageNumber: number,
}

export type IInviteTeammatesPrivateTeamApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const inviteTeammatesForPrivateTeamApi = (
  params: IInviteTeammatesPrivateTeamApiParams
): IInviteTeammatesPrivateTeamApiResponse => {
  return api.postApi('v2/private-teams/privateAddMember', params, {
    ...auth_header(),
  })
}
/* Invite Teammates for new team | end */

/* Remove member from new team | start */
export type IDeletePrivateTeamMemberApiParams = {
  teamId: number,
  email: string,
  pageNumber: number,
}

export type IDeletePrivateTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const deletePrivateTeamMemberApi = (
  params: IDeletePrivateTeamMemberApiParams
): IDeletePrivateTeamMemberApiResponse => {
  return api.postApi('v2/private-teams/removeMember', params, {
    ...auth_header(),
  })
}
/* Remove member from new team | end */

/* Change role of new team member | start */
export type IChangeRolePrivateTeamMemberApiParams = {
  teamId: number,
  email: string,
  pageNumber: number,
}

export type IChangeRolePrivateTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const changeRolePrivateTeamMemberApi = (
  params: IChangeRolePrivateTeamMemberApiParams
): IChangeRolePrivateTeamMemberApiResponse => {
  return api.postApi('v2/private-teams/changeRole', params, {
    ...auth_header(),
  })
}
/* Change role of new team member | end */

/* Resend invite to new team member | start */
export type IResendInviteToPrivateTeamMemberApiParams = {
  teamId: number,
  email: string,
}

export type IResendInvitePrivateTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const resendInviteToPrivateMemberTeamApi = (
  params: IResendInviteToPrivateTeamMemberApiParams
): IResendInvitePrivateTeamMemberApiResponse => {
  return api.postApi('v2/private-teams/resendInvitePrivateTeam', params, {
    ...auth_header(),
  })
}
/* Resend invite to new team member | end */

export interface TeamInviteMembersV2ApiBody {
  emails: string[];
  resendInvite?: boolean;
}

export interface TeamInviteMembersV2ApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    message: string,
  };
}

export const teamInviteMemberV2Api = (
  teamId: string,
  body: TeamInviteMemberV2ApiBody
): TeamInviteMemberV2ApiResponse =>
  apiRequestServiceV2.post(`v2/teams/${teamId}/members`, body, auth_header())

export interface TeamGetInviteLinkTokenApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    token: string,
  };
}

export const teamGetInviteLinkTokenV2Api = (
  teamId: string
): TeamGetInviteLinkTokenApiResponse => {
  return apiRequestServiceV2.get(
    `v2/teams/${teamId}/invite-link-token`,
    auth_header()
  )
}

export interface TeamGetDataByIdApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    name: string,
    image: string,
    createdByName: string,
  };
}

export const teamGetDataByIdV2Api = (
  teamId: string
): TeamGetDataByIdApiResponse => {
  return apiRequestServiceV2.get(`v2/teams/${teamId}/image-and-name`)
}
interface TeamMember {
  id: number;
  username: string;
  realName: string;
  email: string;
  phone: string;
  image: string;
}
export interface GetTeamMembersApiResponse {
  statusCode: number;
  error?: string;
  message?: string;
  data?: {
    groupMembers: TeamMember[]
  };
}

export function getTeamMembersApi(teamId: number): GetTeamMembersApiResponse {
  return apiRequestServiceV2.get(`v2/teams/${teamId}/members`, auth_header())
}
