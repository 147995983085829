import { observable, action } from 'mobx'
import {
  ITeamMemberData,
  IGetTeamMembersApiParams,
  ISearchTeamMembersApiParams,
  IInviteTeammatesApiParams,
  IDeleteTeamMemberApiParams,
  IChangeRoleTeamMemberApiParams,
  IResendInviteToTeamMemberApiParams,
  getTeamMembers,
  searchTeamMembers,
  deleteTeamMemberApi,
  changeRoleTeamMemberApi,
  resendInviteToTeamMemberApi,
} from 'services/base-team'
import { teamInviteMemberV2Api } from 'services/teams'
import { errorToast } from 'utils'
import { DEFAULT_PAGINATION_LIMIT } from 'common/constants'

export type IInitialState = {
  data: {
    teamMembers: ITeamMemberData,
    searchTeamMembers: ITeamMemberData,
    searchText: string,
  },
  ui: {
    isTeamMembersLoading: boolean,
    isTeamMembersError: boolean,
    isTeamMembersSearchLoading: boolean,
    isInviteTeammatesModalOpen: boolean,
    isInviteTeammatesLoading: boolean,
    isMemberRemoveLoading: boolean,
    isMemberChangeRoleLoading: boolean,
    isMemberResendInviteLoading: boolean,
  },
}

export const initialState: IInitialState = {
  data: {
    teamMembers: {},
    searchTeamMembers: {},
    searchText: null,
  },
  ui: {
    isTeamMembersLoading: true,
    isTeamMembersError: false,
    isTeamMembersSearchLoading: false,
    isInviteTeammatesModalOpen: false,
    isInviteTeammatesLoading: false,
    isMemberRemoveLoading: false,
    isMemberChangeRoleLoading: false,
    isMemberResendInviteLoading: false,
  },
}

class BaseTeamStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  onGetTeamMembers = async (params: IGetTeamMembersApiParams) => {
    const { ui, data } = this.state
    const { isDisableLoading = false } = params
    this.setState({ ui: { ...ui, isTeamMembersLoading: !isDisableLoading } })
    try {
      const response = await getTeamMembers(params)

      const teamMembersWithoutDefaults = (response?.data?.teamUsers || []).filter((user) => user.role !== "default")
      const teamMembers = {
        data: teamMembersWithoutDefaults,
        totalMembers: response?.data?.totalMembers || 0,
        pageNumber: response?.data?.pageNumber || 0,
        paginationLimit:
          Math.ceil(response?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
            1 || 0,
      }
      this.setState({
        ui: { ...ui, isTeamMembersLoading: false, isTeamMembersError: false },
        data: { ...data, teamMembers },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isTeamMembersLoading: false, isTeamMembersError: true },
      })
    }
  }

  onSetSearchText = ({ searchText }) => {
    const { ui, data } = this.state
    this.setState({
      ...ui,
      data: {
        ...data,
        searchText,
        searchTeamMembers: searchText ? data?.searchTeamMembers : {},
      },
    })
  }

  onSearchTeamMembers = async (params: ISearchTeamMembersApiParams) => {
    const { ui, data } = this.state
    this.setState({ ui: { ...ui, isTeamMembersSearchLoading: true } })
    try {
      const response = await searchTeamMembers(params)
      if (response?.status) {
        const searchTeamMembers = {
          data: response?.data?.teamUsers || null,
          totalMembers: response?.data?.totalMembers || 0,
          pageNumber: response?.data?.pageNumber || 0,
          paginationLimit:
            Math.ceil(response?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
              1 || 0,
        }
        this.setState({
          ui: {
            ...ui,
            isTeamMembersSearchLoading: false,
          },
          data: { ...data, searchTeamMembers },
        })
      } else {
        errorToast(response?.message)
        this.setState({
          ui: { ...ui, isTeamMembersSearchLoading: false },
        })
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isTeamMembersSearchLoading: false },
      })
    }
  }

  onHandleInviteTeammatesModal = (value: boolean) => {
    const { ui } = this.state
    this.setState({
      ui: { ...ui, isInviteTeammatesModalOpen: value },
    })
  }

  onInviteTeammates = async (params: IInviteTeammatesApiParams) => {
    const { ui, data } = this.state
    this.setState({ ui: { ...ui, isInviteTeammatesLoading: true } })
    try {
      await teamInviteMemberV2Api(params.teamId, {
        emails: params.invities,
        resendInvite: true,
      })

      const getResponse = await getTeamMembers({ pageNumber: 0 })

      const teamMembers = {
        data: getResponse?.data?.teamUsers || null,
        totalMembers: getResponse?.data?.totalMembers || 0,
        pageNumber: getResponse?.data?.pageNumber || 0,
        paginationLimit:
          Math.ceil(getResponse?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
            1 || 0,
      }
      this.setState({
        ui: {
          ...ui,
          isInviteTeammatesLoading: false,
          isInviteTeammatesModalOpen: false,
        },
        data: { ...data, teamMembers },
      })
      return Promise.resolve(true)
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isInviteTeammatesLoading: false },
      })
      return Promise.resolve(false)
    }
  }

  onRemoveMember = async (params: IDeleteTeamMemberApiParams) => {
    const { ui, data } = this.state
    this.setState({ ui: { ...ui, isMemberRemoveLoading: true } })
    try {
      await deleteTeamMemberApi(params)
      
      const getResponse = await getTeamMembers({ pageNumber: 0 })

      const teamMembers = {
        data: getResponse?.data?.teamUsers || null,
        totalMembers: getResponse?.data?.totalMembers || 0,
        pageNumber: getResponse?.data?.pageNumber || 0,
        paginationLimit:
          Math.ceil(getResponse?.data?.totalMembers / DEFAULT_PAGINATION_LIMIT) -
            1 || 0,
      }
      this.setState({
        ui: { ...ui, isMemberRemoveLoading: false },
        data: {
          ...data,
          teamMembers,
        },
      })
      return Promise.resolve(true)
    } catch (error) {
      this.setState({
        ui: { ...ui, isMemberRemoveLoading: false },
      })
      const { message } = error
      errorToast(message)
      return Promise.reject(false)
    }
  }

  onChangeRoleOfMember = async (params: IChangeRoleTeamMemberApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isMemberChangeRoleLoading: true } })
    try {
      const response = await changeRoleTeamMemberApi(params)
      if (response?.status) {
        this.setState({
          ui: { ...ui, isMemberChangeRoleLoading: false },
        })
        return Promise.resolve(true)
      } else {
        errorToast(response?.message)
        this.setState({
          ui: { ...ui, isMemberChangeRoleLoading: false },
        })
        return Promise.resolve(false)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isMemberChangeRoleLoading: false },
      })
      return Promise.reject(false)
    }
  }

  onResendInviteToMember = async (
    params: IResendInviteToTeamMemberApiParams
  ) => {
    try {
      const { status, message } = await resendInviteToTeamMemberApi(params)
      if (!status) {
        errorToast(message)
      }
      return Promise.resolve(true)
    } catch (error) {
      const { message } = error
      errorToast(message)
      return Promise.reject(false)
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default BaseTeamStore
