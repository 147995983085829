import Axios from 'axios'

export class ApiRequestServiceV2 {
  constructor(baseUrl) {
    this.axiosService = Axios.create({
      baseURL: baseUrl,
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    })
  }

  async get(path: string, headers = {}) {
    try {
      const response = await this.axiosService.get(path, { headers })

      return this.handleResponse(response)
    } catch (error) {
      console.log(`Error calling GET ${path}.\n`, error)

      this.handleError(error)
    }
  }

  async post(path: string, body = {}, headers = {}) {
    try {
      const response = await this.axiosService.post(path, body, { headers })

      return this.handleResponse(response)
    } catch (error) {
      console.log(`Error calling POST ${path}.\n`, error)

      this.handleError(error)
    }
  }

  async put(path: string, body = {}, headers = {}) {
    try {
      const response = await this.axiosService.put(path, body, { headers })

      return this.handleResponse(response)
    } catch (error) {
      console.log(`Error calling PUT ${path}.\n`, error)

      this.handleError(error)
    }
  }

  async patch(path: string, body = {}, headers = {}) {
    try {
      const response = await this.axiosService.patch(path, body, { headers })

      return this.handleResponse(response)
    } catch (error) {
      console.log(`Error calling PATCH ${path}.\n`, error)

      this.handleError(error)
    }
  }

  async delete(path: string, headers = {}) {
    try {
      const response = await this.axiosService.delete(path, { headers })

      return this.handleResponse(response)
    } catch (error) {
      console.log(`Error calling DELETE ${path}.\n`, error)

      this.handleError(error)
    }
  }

  handleResponse(response) {
    if (response && response.data) {
      return response.data
    }

    return response
  }

  handleError(error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      throw new Error(error.response.data.message)
    }

    throw error
  }
}

export default ApiRequestServiceV2
