import React from 'react'
import { images } from 'config/images'
import { PLAN_FREE } from 'common/constants'
import { roleTypes } from 'config/roles'

import './HeaderMobileView.scss'

export const HeaderMobileView = props => {
  const {
    teamName,
    userRole,
    teamLogo,
    currentPlan,
    onMenuToggle,
    onUpgradePlan,
    onAddSeats,
    planStatus,
    isHideUpgradeButton,
  } = props

  const planUpgradeButton = () => {
    return !isHideUpgradeButton &&
      currentPlan &&
      Object.keys(currentPlan).length ? (
      <div className="Header__Userinfo__right">
        {userRole !== roleTypes.user &&
          (currentPlan?.nickname === PLAN_FREE ? (
            <div className="btn__upgrade" onClick={onUpgradePlan}>
              Upgrade
            </div>
          ) : (
            <div className="btn__upgrade" onClick={onAddSeats}>
              Upgrade
            </div>
          ))}
      </div>
    ) : null
  }

  return (
    <div className="HeaderMobileView__main">
      <div className="Header__toprow">
        <div className="Header__Userinfo__left">
          <div className="Header__ico__Sidemenu" onClick={onMenuToggle}>
            <img
              className="menu-icon ico_menu"
              src={images.ico_menu}
              alt="menu"
            />
            <img
              className="menu-icon ico_close"
              src={images.ico_close}
              alt="menu"
            />
          </div>
        </div>
        {planUpgradeButton()}
      </div>
      <div className="Header__bottomrow">
        <div className="Header__Userinfo__left">
          <div className="Header__UserPic">
            <img
              className="menu-icon"
              src={teamLogo ? teamLogo : images.yaclogo}
              alt="teamLogo"
            />
          </div>
          <div className="Header__UserTeam__name">{teamName}</div>
        </div>
        <div className="Header__Userinfo__right">
          {userRole !== roleTypes.user && planStatus && (
            <div className="UserTeam__plan">
              <label>{planStatus}</label>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
