import React, { Component } from 'react'
import './Loader.scss'
import { PropagateLoader } from 'react-spinners'

class Loader extends Component {
  render() {
    return (
      <div className={`custom-loader ${this.props.className || ""}`}>
        <PropagateLoader size={30} color={'#f3c93c'} />
      </div>
    )
  }
}

export default Loader
