import ApiRequestService from 'services/api.service'
import { IPlan, IUserData } from './auth'
import { auth_header } from 'services/utils'

const api = new ApiRequestService()

export type IBillingHistory = {
  id: string,
  aggregate_usage: string,
  amount: number,
  amount_decimal: string,
  billing_scheme: string,
  created: number,
  currency: string,
  interval: string,
  interval_count: number,
  livemode: boolean,
  metadata: object,
  nickname: string,
  object: string,
  product: string,
  tiers: string,
  tiers_mode: string,
  transform_usage: string,
  trial_period_days?: number,
  remaining_days?: number,
  usage_type: string,
  active: boolean,
}

export type IBillingDetails = {
  cardDetails: {
    month: number,
    year: number,
    last4: string,
    name: string,
  },
  companyDetails: {
    id: number,
    country: string,
    state: string,
    address1: string,
    address2: string,
    city: string,
    zipCode: number,
  },
  paymentDetails: {
    lastPayment: number,
    nextPayment: number,
    quantity: number,
  },
}

/* get billing details | start */
export type IGetBillingDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IBillingDetails,
}

export const getBillingDetailsApi = (): IGetBillingDetailsApiResponse => {
  return api.getApi(`v1/billing/billing-details`, {
    ...auth_header(),
  })
}
/* get billing details | end */

/* get billing history list | start */
export type IGetBillingHistoryApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IBillingHistory[],
}

export const getBillingHistoryApi = (): IGetBillingHistoryApiResponse => {
  return api.getApi(`v1/billing/history`, {
    ...auth_header(),
  })
}
/* get billing history list | end */

/* save billing details (Company details, Payment details) | start */
export type ISaveBillingDetailsApiParams = {
  address1: string,
  address2?: string,
  country: string,
  state: string,
  city: string,
  zipCode: string,
  paymentToken?: string,
}

export type ISaveBillingDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const saveBillingDetailsApi = (
  params: ISaveBillingDetailsApiParams
): ISaveBillingDetailsApiResponse => {
  return api.postApi('v1/billing/update', params, {
    ...auth_header(),
  })
}

export type UpgradePlanApiParams = {
  planId: string,
  couponCode?: string,
  paymentToken: string,
}

export const upgradePlan = (
  params: UpgradePlanApiParams
): UpgradePlanApiParams => {
  return api.postApi('v1/billing', params, {
    ...auth_header(),
  })
}
/* save billing details (Company details, Payment details) | end */

/* Billing plans list*/
export const getBillingPlansList = () => {
  return api.getApi(`v1/billing/plan/list`, {
    ...auth_header(),
  })
}
/* Billing plans list*/

/* update billing */
export type BillingApiParams = {
  paymentToken: string,
}
export const upgradeBillingInfo = (
  params: BillingApiParams
): BillingApiParams => {
  return api.postApi('v1/billing/update', params, {
    ...auth_header(),
  })
}
/* update billing */

export type validatePCParams = {
  couponCode: string,
  planId: string,
}
/* validate promocode */
export const validatePromoCode = (promocode, planId) => {
  return api.getApi(`v1/billing/coupon-details/${promocode}/${planId}`, {
    ...auth_header(),
  })
}
/* validate promocode */

/* get plan details | start */
export type IGetPlanDetailsApiParams = {
  planId: string,
}

export type IGetPlanDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IBillingHistory,
}

export const getPlanDetailsApi = (
  params: IGetPlanDetailsApiParams
): IGetPlanDetailsApiResponse => {
  const { planId } = params
  return api.getApi(`v1/billing/plan-details/${planId}`, {
    ...auth_header(),
  })
}
/* get plan details | end */

/* downgrade account | start */
export type IDowngradeAccountApiParams = {
  planId: string,
}

export type IDowngradeAccountApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IBillingHistory,
}

export const downgradeAccount = (): IDowngradeAccountApiResponse => {
  return api.postApi(
    'v1/billing/plan/cancel',
    {},
    {
      ...auth_header(),
    }
  )
}
/* downgrade account | end */

/* get billing details | start */
export type IBillingPlanDetails = {
  allowedFeatures: string[],
  plan: IPlan,
  user: IUserData,
}

export type IGetBillingPlanDetailsApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: IBillingPlanDetails,
}

export const getBillingPlanDetailsApi = (): IGetBillingPlanDetailsApiResponse => {
  return api.getApi(`v2/billing/billing-details`, {
    ...auth_header(),
  })
}
/* get billing details | end */
