export const countries = [
  {
    value: 'United States',
    label: 'United States',
  },
]

export const states = [
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
]
