import React, { Component } from 'react'
import './AlertBanner.scss'
import { Link } from "react-router-dom";

class AlertBanner extends Component {
    render() {
        return (
            <div className={`AlertBanner_container${this.props.visible ? "" : " hidden"}`}>
                <div className="AlertBanner_message">
                    { this.props.message === "extteam" 
                        ? <h1>You're already on another team but if you'd like to manage your own create one here.</h1>
                        : <h1>Use promo code <span>TRY30</span> to unlock group messaging and the desktop app for free for 30 days. <span className="UpgradeLink"><Link to="/billing">Upgrade Now.</Link></span></h1>
                    }
                </div>
                <button className="AlertBanner_close" onClick={this.props.hideAlertBanner}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#0D1C31" fillRule="nonzero" d="M15.535 7.05l1.414 1.414L13.414 12l3.535 3.536-1.414 1.414L12 13.414 8.465 16.95l-1.414-1.414L10.586 12 7.051 8.464 8.465 7.05 12 10.586l3.535-3.536zM12 22c5.514 0 10-4.485 10-10 0-5.514-4.486-10-10-10S2 6.486 2 12c0 5.515 4.486 10 10 10zm0-22c6.617 0 12 5.383 12 12s-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0z" opacity=".4"/>
                    </svg>
                </button>
            </div>
        )
    }
}



export default AlertBanner