import { observable, action } from 'mobx'
import { routes, history } from 'config/routes'
import moment from 'moment'
import { countries, states } from 'common/mockData'
import { PLAN_FREE } from 'common/constants'

import {
  IPlansData,
  IGetSelectPlanApiParams,
  ICountries,
  IStates,
  IPurchaseStandardPlanApiParams,
  getPlansApi,
  getSelectPlanApi,
  checkPromoCodeApi,
  purchaseStandardPlanApi,
  getSubscribedPlanDetailsApi,
  getCurrentPlanDetailsApi,
} from 'services/plans'
import { errorToast } from 'utils'

export type IInitialState = {
  plans: IPlansData[],
  plan: IPlansData,
  countries: ICountries[],
  states: IStates[],
  ui: {
    isPlansLoading: boolean,
    isPlanLoading: boolean,
    isPromoCodeChecking: boolean,
    isStandardPlanFormSubmitting: boolean,
    isPaymentMethodEditable: boolean,
  },
}

export const initialState: IInitialState = {
  plans: [],
  plan: {},
  countries: countries,
  states: states,
  ui: {
    isPlansLoading: false,
    isPlanLoading: false,
    isPromoCodeChecking: false,
    isStandardPlanFormSubmitting: false,
    isPaymentMethodEditable: true,
  },
}

class PlansStore {
  @observable state: IInitialState
  @observable showBetaExpRibbonError = true
  @observable showPlannedExpRibbonError = true

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  @action.bound
  isFreePlanExpired = () => {
    const {
      state: {
        userData: { plan },
      },
      planDays,
    } = this.rootStore.AuthStore
    if (plan?.nickname === PLAN_FREE) {
      if (planDays < 1) {
        return true
      }
      return false
    }
    return false
  }

  planDays = date => {
    const currentDate = moment(new Date())
    const endDate = moment(date)
    const days = endDate.diff(currentDate, 'days')

    return days > 0 ? days : 0
  }

  @action.bound
  isBuyPlanExpired = () => {
    const {
      state: {
        userData: { plan },
      },
      planDays,
    } = this.rootStore.AuthStore
    if (plan?.nickname !== PLAN_FREE) {
      if (planDays < 1) {
        return true
      }
      return false
    }
    return false
  }

  toggleBetaExpiryRibbonError = val => {
    this.showBetaExpRibbonError = val
  }

  togglePlanExpiryRibbonError = val => {
    this.showPlannedExpRibbonError = val
  }

  onGetPlans = async () => {
    this.onGetSubscribedPlanDetails()
    const { ui } = this.state
    this.setState({ ui: { ...ui, isPlansLoading: true } })
    try {
      const response = await getPlansApi()
      const { message, status, data } = response
      if (status && data?.plans && data.plans.length > 0) {
        this.setState({ plans: data.plans, teamSize: data.teamSize })
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isPlansLoading: false } })
  }

  onSelectPlan = planId => {
    history.push({
      pathname: `${routes.plans.path}/${planId}`,
    })
  }

  onSelectChangePlan = planId => {
    history.push({
      pathname: `${routes.upgradeplan.path}/${planId}`,
    })
  }

  onGetSelectPlan = async (params: IGetSelectPlanApiParams) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isPlanLoading: true } })
    try {
      const response = await getSelectPlanApi(params)
      const { message, status, data } = response
      if (status && data) {
        const { companyDetails, cardDetails } = data
        const { state, country } = companyDetails
        const selectState = states.find(c => c.value === state)
        const selectCountry = countries.find(c => c.value === country)
        this.setState({
          ui: {
            ...ui,
            isPaymentMethodEditable: Object.keys(cardDetails).length
              ? false
              : true,
            isPlanLoading: false,
          },
          plan: {
            ...data,
            companyDetails: {
              ...companyDetails,
              state: selectState ? selectState : state,
              country: selectCountry ? selectCountry : country,
            },
          },
        })
      } else {
        errorToast(message)
        this.setState({ ui: { ...ui, isPlanLoading: false } })
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({ ui: { ...ui, isPlanLoading: false } })
    }
  }

  onCheckPromoCode = async params => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isPromoCodeChecking: true } })
    try {
      const response = await checkPromoCodeApi(params)
      this.setState({ ui: { ...ui, isPromoCodeChecking: false } })
      return Promise.resolve(response)
    } catch (error) {
      this.setState({ ui: { ...ui, isPromoCodeChecking: false } })
      return Promise.reject(error)
    }
  }

  onGetCurrentPlanPaymentDetails = async params => {
    const { ui } = this.state
    this.setState({ ui: { ...ui } })
    try {
      const response = await getCurrentPlanDetailsApi(params)
      this.setState({ ui: { ...ui } })
      return Promise.resolve(response.data)
    } catch (error) {
      this.setState({ ui: { ...ui } })
      return Promise.reject(error)
    }
  }

  onPurchasePlan = async (params: IPurchaseStandardPlanApiParams, stripe) => {
    const { ui } = this.state
    const { isPaymentMethodEditable } = ui
    const { fullNameOnCard, ...rest } = params
    this.setState({ ui: { ...ui, isStandardPlanFormSubmitting: true } })
    if (!isPaymentMethodEditable) {
      this.onPurchaseStandardPlan(rest)
    } else {
      if (stripe) {
        try {
          const payload = await stripe.createToken({ name: fullNameOnCard })
          if (payload?.token?.id) {
            await this.onPurchaseStandardPlan({
              paymentToken: payload.token.id,
              ...rest,
            })
            this.setState({ ui: { ...ui, isPaymentMethodEditable: false } })
          } else if (payload?.error) {
            const {
              error: { message },
            } = payload
            errorToast(message)
            this.setState({
              ui: { ...ui, isStandardPlanFormSubmitting: false },
            })
          }
        } catch (error) {
          this.setState({ ui: { ...ui, isStandardPlanFormSubmitting: false } })
        }
      } else {
        console.log("Stripe.js hasn't loaded yet.")
      }
    }
  }

  onPurchaseStandardPlan = async (params: IPurchaseStandardPlanApiParams) => {
    const { ui } = this.state
    try {
      const response = await purchaseStandardPlanApi(params)
      const { message, status } = response
      if (status) {
        await this.onGetSubscribedPlanDetails()
        this.setState({
          ui: { ...ui, isStandardPlanFormSubmitting: false },
        })
        // history.push({
        //   pathname: `${routes.planUpgraded.path}`,
        // })
      } else {
        errorToast(message)
      }
      this.setState({
        ui: { ...ui, isStandardPlanFormSubmitting: false },
      })
    } catch (error) {
      const { message } = error
      errorToast(message)
      this.setState({
        ui: { ...ui, isStandardPlanFormSubmitting: false },
      })
    }
  }

  onGetSubscribedPlanDetails = async () => {
    const { AuthStore } = this.rootStore
    const {
      state: { userData },
    } = AuthStore
    try {
      const response = await getSubscribedPlanDetailsApi()
      const { message, status, data } = response
      if (status && data) {
        const updateUser = {
          ...userData,
          plan: data,
        }
        await AuthStore.setUserData(updateUser)
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default PlansStore
