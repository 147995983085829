import { getState } from './localStorage'

export const content_types = {
  multipart: {
    'Content-Type': 'multipart/form-data',
  },
  json: {
    'Content-Type': 'application/json',
  },
}

export const auth_header = (token?: string) => {
  return { authorization: token || getState('token') }
}
