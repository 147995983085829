import ApiRequestService from 'services/api.service'
import { auth_header } from 'services/utils'
import { CONFIG } from 'config/config.env'

const api = new ApiRequestService()

export type ITeamMember = {
  id: string,
  realName: string,
  email: string,
  role: string,
  isActive: number,
}

export type ITeamMemberData = {
  teamUsers: ITeamMember[],
  totalMembers: number,
  pageNumber: number,
}

/* get team members | start */
export type IGetTeamMembersApiParams = {
  pageNumber: Number,
  isDisableLoading?: boolean,
}

export type IGetTeamMembersApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamMemberData,
}

export const getTeamMembers = async (
  params: IGetTeamMembersApiParams
): IGetTeamMembersApiResponse => {
  const { pageNumber } = params

  const response = await api.getApi(`v1/team/getTeamMembers/${pageNumber}`, {
    ...auth_header(),
  })

  if (response.status) {
    return response
  }

  throw new Error(response?.message || "an unexpected error occurred")
}
/* get team members  | end */

/* search team members | start */
export type ISearchTeamMembersApiParams = {
  search: string,
  pageNumber: Number,
}

export type ISearchTeamMembersApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: ITeamMemberData,
}

export const searchTeamMembers = (
  params: ISearchTeamMembersApiParams
): ISearchTeamMembersApiResponse => {
  return api.getApi(
    `v1/team/searchTeamMember`,
    {
      ...auth_header(),
    },
    params
  )
}
/* search team members  | end */

/* Invite Teammates via email | start */
export type IInviteTeammatesApiParams = {
  invities: string[],
  teamId: number,
  pageNumber: number,
  newUserData?: {
    email: string,
    realName: string,
    username: string,
  },
  isCalledViaInviteLink?: boolean,
  isCalledViaPrivateInviteLink?: boolean,
  isExistingUser?: boolean,
}

export type IInviteTeammatesApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const inviteTeammatesApi = (
  params: IInviteTeammatesApiParams,
  authToken?: string
): IInviteTeammatesApiResponse => {
  return api.postApi('v1/team/inviteNewMember', params, auth_header(authToken))
}
/* Invite Teammates via email | end */

/* Remove member from team | start */
export type IDeleteTeamMemberApiParams = {
  teamId: number,
  email: string,
  pageNumber: number,
}

export type IDeleteTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const deleteTeamMemberApi = async (
  params: IDeleteTeamMemberApiParams
): IDeleteTeamMemberApiResponse => {
  const response = await api.postApi('v1/team/removeMember', params, {
    ...auth_header(),
  })

  if (response.status) {
    return response
  }

  throw new Error(response?.message || "an unexpected error occurred")
}
/* Remove member from team | end */

/* Change role of team member | start */
export type IChangeRoleTeamMemberApiParams = {
  teamId: number,
  email: string,
  pageNumber: number,
}

export type IChangeRoleTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const changeRoleTeamMemberApi = (
  params: IChangeRoleTeamMemberApiParams
): IChangeRoleTeamMemberApiResponse => {
  return api.postApi('v1/team/changeRole', params, {
    ...auth_header(),
  })
}
/* Change role of team member | end */

/* Resend invite to team member | start */
export type IResendInviteToTeamMemberApiParams = {
  teamId: number,
  email: string,
}

export type IResendInviteTeamMemberApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: object,
}

export const resendInviteToTeamMemberApi = (
  params: IResendInviteToTeamMemberApiParams
): IResendInviteTeamMemberApiResponse => {
  return api.postApi('v1/team/resendInvite', params, {
    ...auth_header(),
  })
}
/* Resend invite to team member | end */

export type ShortenUrlApiParams = {
  url: string,
  regenerate: 0 | 1,
}

export type ShortenUrlApiResponse = {
  message: string,
  status: boolean,
  statusText: string,
  data: {
    url: string,
    regenerate: 0 | 1,
  },
}

const urlShortenerApi = new ApiRequestService(CONFIG.SHORTENING_SERVICE_URL)

export const shortenUrlApi = (
  params: ShortenUrlApiParams
): ShortenUrlApiResponse => {
  return urlShortenerApi.postApi('api/v1/generate', params, {})
}
