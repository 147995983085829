import Axios from 'axios'
import Configration from './../config/config.env'

export class ApiRequestService {
  constructor(baseURL) {
    this.baseURL = baseURL || Configration.CONFIG.PUBLIC_URL

    this.axiosService = Axios.create({
      baseURL: this.baseURL,
      // timeout: 60000,
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    })
  }

  getApi(path, headers = {}, params = {}) {
    return new Promise(resolve => {
      this.axiosService
        .get(path, {
          headers,
          params,
        })
        .then(res => {
          resolve({
            message: res.data.message,
            status: res.data.status,
            statusText: 'Success',
            data: res.data.data,
          })
        })
        .catch(error => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          })
        })
        .then(() => {
          // here the code which should be excuted always
        })
    })
  }

  postApi(path, apiData, headers = {}) {
    return new Promise(resolve => {
      this.axiosService
        .post(path, apiData, {
          headers,
        })
        .then(res => {
          if (this.baseURL === Configration.CONFIG.SHORTENING_SERVICE_URL) {
            resolve({
              message: 'Success',
              status: res.status,
              statusText: 'Success',
              data: res.data,
            })
          } else {
            resolve({
              message: res.data ? res.data.message : '',
              status: res.data.status,
              statusText: 'Success',
              data: res.data.data,
            })
          }
        })
        .catch(error => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          })
        })
        .then(() => {
          // here the code which should be excuted always
        })
    })
  }

  deleteApi(path, apiData) {
    return new Promise(resolve => {
      this.axiosService
        .delete(path, apiData)
        .then(res => {
          resolve({
            message: res.data.message,
            status: res.data.status,
            statusText: 'Success',
            data: res.data.data,
          })
        })
        .catch(error => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          })
        })
        .then(() => {
          // here the code which should be excuted always
        })
    })
  }

  putApi(path, apiData, headers = {}) {
    return new Promise(resolve => {
      this.axiosService
        .put(path, apiData, {
          headers,
        })
        .then(res => {
          resolve({
            message: res.data.message,
            status: res.data.status,
            statusText: 'Success',
            data: res.data.data,
          })
        })
        .catch(error => {
          resolve({
            message: error?.response?.data?.message,
            status: false,
            statusText: 'Error',
          })
        })
        .then(() => {
          // here the code which should be excuted always
        })
    })
  }
}

export default ApiRequestService
