import { observable, action } from 'mobx'
import { routes, history } from 'config/routes'
import configration from 'config/config.env'

export type IInitialState = {
  ui: {
    isDownloadForMACLoading: boolean,
    isDownloadForWindowsLoading: boolean,
    isBetaModalOpen: Boolean,
  },
}

export const initialState: IInitialState = {
  ui: {
    isDownloadForMACLoading: false,
    isDownloadForWindowsLoading: false,
    isBetaModalOpen: false,
  },
}

class DownloadYacStore {
  @observable state: IInitialState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.state = initialState
  }

  downloadYac = (isMac, isAuthenticated) => {
    const downloadYacLink = isMac
      ? configration.CONFIG.MAC_BUILD
      : configration.CONFIG.WIN_BUILD
    if (!isAuthenticated) {
      history.push({
        pathname: routes.thankYou.path,
        state: {
          isActiveUser: false,
          isMac,
        },
      })
      setTimeout(() => {
        this.generateLink(downloadYacLink)
      }, 1000)
    } else {
      this.generateLink(downloadYacLink)
    }
  }

  generateLink = downloadYacLink => {
    let linkTag = document.createElement('a')
    linkTag.setAttribute('href', downloadYacLink)
    linkTag.setAttribute('rel', 'no-refresh')
    document.body.appendChild(linkTag)
    linkTag.click()
    document.body.removeChild(linkTag)
  }

  onDownloadForMAC = isAuthenticated => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isDownloadForMACLoading: true } })
    this.downloadYac(true, isAuthenticated)
    this.setState({ ui: { ...ui, isDownloadForMACLoading: false } })
  }

  showBetaModal = isAuthenticated => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isBetaModalOpen: true } })
  }

  hideBetaModal = isAuthenticated => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isBetaModalOpen: false } })
  }

  onDownloadForWindows = isAuthenticated => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isDownloadForWindowsLoading: true } })
    this.downloadYac(false, isAuthenticated)
    this.setState({ ui: { ...ui, isDownloadForWindowsLoading: false } })
  }

  @action
  setState = (params: IInitialState) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
  }

  @action
  reset = () => {
    this.state = initialState
  }
}

export default DownloadYacStore
